import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hs-shimmer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss']
})
export class ShimmerComponent {

  @Input() width = '80%';
  @Input() height = '0.75rem';
  @Input() shape: 'circle' | 'square' | 'rect' = 'rect';
  @Input() borderRadius = '0.313rem';
  @Input() paddingTop = '0';
  @Input() paddingBottom = '0';
  @Input() paddingLeft = '0';
  @Input() paddingRight = '0';
  @Input() lightBackground = true;

  constructor() { }

  get shimmerHeight(): string {
    switch (this.shape) {
      case 'circle':
        return this.width;
      case 'square':
        return this.width;
      case 'rect':
        return this.height;
      default:
        return this.height;
    }
  }

  get shimmerBorderRadius(): string {
    return this.shape === 'circle' ? '50%' : this.borderRadius;
  }
}
