import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Price } from '../../models/price.model';


@Component({
  selector: 'hs-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.scss']
})
export class LicensesListComponent implements OnInit {


  @Input() prices: Price[];
  @Input() lightBackground = false;
  @Input() showBullets = false;
  @Input() strikethroughPink = true;
  @Input() selectedPrice: Price;
  @Output() selectedPriceChange = new EventEmitter<Price>();


  constructor() { }

  ngOnInit(): void {

  }

  setSelected(price: Price) {
    this.selectedPrice = price;
    this.selectedPriceChange.emit(price);
}
}
