import { Wishlist } from 'src/app/shared/models/wishlist.model';
import { DeleteComponent } from '../../profile/pages/wishlist/pages/delete/delete.component';
import { TrackModalV2Component } from '../components/track-modal-v2/track-modal-v2.component';
import { WishlistAddComponent } from '../components/wishlist-add/wishlist-add.component';
import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Track } from '../models/tracks-sfx/track.model';
import { InvoiceComponent } from 'src/app/profile/pages/invoice/invoice.component';
import { SubscriptionModalComponent } from '../components/subscription-modal/subscription-modal.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {


  bsModalRef: BsModalRef;
  wishlist: BsModalRef;
  invoice: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  /**
   * Opens a modal that contains the licenses and subscriptions available
   * for the track when the download button in a track is clicked.
   */
  openDownloadTrack(t: Track) {
    const initialState = {
      track: t,
      title: 'Modal'
    };
    this.bsModalRef = this.modalService.show(TrackModalV2Component, { initialState, class: 'modal-dialog-centered' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  // openDownloadTrackV2(t: Track) {
  //   const initialState = {
  //     track: t,
  //     title: 'Modal'
  //   };
  //   this.bsModalRef = this.modalService.show(TrackModalV2Component, { initialState, class: 'modal-dialog-centered'});
  //   this.bsModalRef.content.closeBtnName = 'Close';
  // }

  openSubscription(proSelected: boolean) {
    const initialState = {
      proSelected,
      title: 'Modal'
    };
    this.bsModalRef = this.modalService.show(SubscriptionModalComponent, {
      initialState,
      class: 'modal-dialog-centered'
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openAddWishlist(trackId: number) {
    const initialState = {
      trackId,
      title: 'Wishlist'
    };
    this.wishlist = this.modalService.show(WishlistAddComponent, { initialState, class: 'modal-dialog-centered' });
    this.wishlist.content.closeBtnName = 'Close';
  }

  openDeleteWishlist(wl: Wishlist) {
    const initialState = {
      wishlist: wl,
      title: 'Delete wishlist'
    };
    this.wishlist = this.modalService.show(DeleteComponent, { initialState, class: 'modal-dialog-centered' });
    this.wishlist.content.closeBtnName = 'Close';
  }

  openInvoice(invoiceHTML: string) {
    const initialState = {
      title: 'Invoice',
      invoiceHTML
    };
    this.invoice = this.modalService.show(InvoiceComponent, { initialState, class: 'modal-dialog-centered modal-lg' });
    this.invoice.content.closeBtnName = 'Close';
  }

}
