import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { UtilsService } from "../../shared/services/utils.service";

@Injectable({ providedIn: 'root' })
/**
 * SvelteGuard is used to redirect to the svelte app.
 */
export class SvelteGuard implements CanActivate {
  constructor(
    private utils: UtilsService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.utils.isBrowser && state.url && state.url !== window.location.pathname) {
      if (!state.url.endsWith('/') && !state.url.includes('?')) {
        state.url += '/';
      }
      window.location.href = state.url;
    }
    return false;
  }
}
