import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { Service } from 'src/app/shared/services/service';
import { isPlatformBrowser } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { ServerCacheService } from "./server-cache.service";
import {ZarazService} from "../../gtag/gtag.service";

@Injectable({
providedIn: 'root',
})
export class UtilsService extends Service {

  public readonly
  seasonalImages = {
    name: "/assets/discounts/spring-green.svg",
    off: "/assets/discounts/35-off-green.svg",
    nameAndOff: "/assets/discounts/valentines/11.svg",
    banner: "/assets/discounts/black-friday-banner.svg",
    bannerBg: "/assets/discounts/new-year-banner-bg.jpg",
    bannerText: "/assets/discounts/valentines/big-banner-text.svg",

  }

  constructor(
    http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(RESPONSE) private serverResponse: any,
    private cacheService: ServerCacheService,
    private gtag: ZarazService
  ) {
    super(http);
  }

  disableResponseCache() {
    if (!this.isBrowser && this.serverResponse) {
      this.serverResponse.locals.disableResponseCache = true;
    }
  }

  setResponseCacheDuration(seconds: number) {
    if (!this.isBrowser && this.serverResponse) {
      this.serverResponse.locals.responseCacheDuration = seconds;
    }
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Loads an external JS file into the site.
   * We use this to load the 2checkout and Bitpay SDKs in the cart only.
   *
   * @param url The script URL to load.
   * @param id The id of the new DOM element.
   * @param defer Whether the script should be loaded with defer enabled.
   * @return ReplaySubject fired onLoad.
   */
  public loadScript(url: string, id: string, defer = true): ReplaySubject<void> {
    if (!this.isBrowser) {
      const result = new ReplaySubject<void>(1);
      result.complete();
      return result;
    }
    const subject = new ReplaySubject<void>(1);
    if (document.getElementById(id)) {
      // Script already loaded, resolve subject immediately.
      subject.next();
      return subject;
    }
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = defer;
    script.id = id;
    script.addEventListener('load', () => {
      subject.next();
    }, false);
    body.appendChild(script);
    return subject;
  }

  public removeScript(id: string) {
    if (!this.isBrowser) {
      return;
    }
    document.getElementById(id)?.remove();
  }
}


export enum CacheTime {
  Hour = 3600,
  ThreeHours = Hour * 3,
  SixHours = Hour * 6,
  Day = Hour * 24,
  ThreeDays = Day * 3,
  Week = Day * 7,
  Month = Day * 30
}
