import { CookiesService } from 'src/app/cookies/cookies.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';
import { Service } from 'src/app/shared/services/service';
import { UserSubscription } from '../models/user-subscription.model';
import { UserAffiliate } from '../models/user-affiliate.model';
import { UtilsService } from '../../shared/services/utils.service';
import { ErrorsService } from '../../shared/services/errors.service';
import { UserInit } from 'src/app/shared/models/user-init.model';
import { ServerCacheService } from 'src/app/shared/services/server-cache.service';
import { ZarazService } from 'src/app/gtag/gtag.service';

class GoogleAuthResult {
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Service {
  currentUser: User = null;
  currentUserSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  subscription: ReplaySubject<UserSubscription> = new ReplaySubject<UserSubscription>(1);
  affiliate: ReplaySubject<UserAffiliate> = new ReplaySubject<UserAffiliate>(1);
  loginEvent: Subject<void> = new Subject<void>();
  logoutEvent: Subject<void> = new Subject<void>();
  rememberMe = true;
  private canSeeDiscountsValue: boolean | null = null;

  constructor(
    http: HttpClient,
    private errorsService: ErrorsService,
    private chat: ChatService,
    private cookiesService: CookiesService,
    private cacheService: ServerCacheService,
    private utils: UtilsService,
    private gtag: ZarazService
  ) {
    super(http);

    if (!this.utils.isBrowser && this.cookiesService.isSet('hsauth')) {
      this.utils.disableResponseCache();
      this.http.post<UserInit>(this.apiUrl + '/v1/userInit', {}).subscribe({
        next: (data) => {
          this.cacheService.set('userInit', data);
          this.autoLogin(data.user);
        },

        error: (error) => {
          this.errorsService.track(error, [
            {
              browser: utils.isBrowser,
              apiUrl: this.apiUrl,
              error: error,
              'ESTEERROR!': '',
            },
          ]);
          this.cookiesService.remove('hsauth');
          this.currentUser = null;
          this.currentUserSubject.next(null);
        },
      });
    } else if (this.utils.isBrowser) {
      let cachedUserInit: UserInit = this.cacheService.get('userInit', null);
      if (cachedUserInit) {
        this.cacheService.remove('userInit');
        this.autoLogin(cachedUserInit.user);
      } else {
        this.currentUser = null;
        this.currentUserSubject.next(null);
        this.subscription.next(new UserSubscription(false, false, false, false, false, ''));
      }
    } else {
      this.currentUser = null;
      this.currentUserSubject.next(null);
    }
  }

  login(username: string, password: string, rememberMe: boolean): Observable<User> {
    return this.http.post<User>(this.apiUrl + '/v2/login', { username, password, rememberMe }).pipe(
      map((user) => {
        this.autoLogin(user, rememberMe);
        return user;
      })
    );
  }

  googleLogin(): Observable<any> {
    return this.http.get<void>(this.apiUrl + '/v2/googleLogin', {});
  }

  autoLogin(user: User, rememberMe: boolean = null) {
    if (this.utils.isBrowser) {
      this.errorsService.setUser(user);
    }
    if (!user) {
      this.logout(false).subscribe();
      return;
    }
    if (this.validateEmail(user.email)) {
      this.chat.setUserEmail(user?.email);
    }
    this.gtag.setUser(user);
    if (rememberMe !== null) {
      this.rememberMe = rememberMe;
    }
    this.setSubscription(user.subscription);
    this.setAffiliate(user.affiliate);
    this.currentUser = user;
    this.currentUserSubject.next(user);
    this.loginEvent.next();
  }

  private validateEmail(email: string) {
    if (!email) {
      return false;
    }
    const expression = /\S+@\S+\.\S+/;
    return expression.test(email);
  }

  public setSubscription(subscription: UserSubscription) {
    subscription = subscription ?? new UserSubscription();
    if (this.currentUser) {
      this.currentUser.subscription = subscription;
      this.currentUserSubject.next(this.currentUser);
    }
    this.subscription.next(subscription);
  }

  public setAffiliate(affiliate: UserAffiliate) {
    affiliate = affiliate ?? new UserAffiliate();
    this.affiliate.next(affiliate);
  }

  logout(callService: boolean = true): Observable<void> {
    if (callService) {
      return this.doLogout().pipe(
        map((_) => {
          this.clearClientAuth();
        })
      );
    } else {
      this.clearClientAuth();
      return new Observable<void>((subject) => {
        subject.next();
      });
    }
  }

  private clearClientAuth() {
    this.setSubscription(null);
    this.setAffiliate(null);
    this.currentUser = null;
    this.currentUserSubject.next(null);
    this.logoutEvent.next();
    this.errorsService.setUser(null);
    // if (!this.utils.isBrowser) {
    //   this.cookiesService.remove('hsauth');
    // }
  }

  private doLogout(): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/v2/logout', null);
  }

  signUp(username: string, email: string, password: string, repeatPassword: string): Observable<User> {
    const data = { email, password };
    return this.http.post<User>(this.apiUrl + '/v2/signup', data).pipe(
      map((user) => {
        this.autoLogin(user, true);
        return user;
      })
    );
  }

  /**
   * Receives either username or email.
   */
  getNewPassword(user: string): Observable<void> {
    const data = { user };
    return this.http.post<void>(this.url + '/snack/v1/resetPassword', data);
  }

  isLoggedInWaitForInit(): Observable<boolean> {
    return this.currentUserSubject.pipe(
      map((user) => {
        return !!user;
      })
    );
  }

  canSeeTrial() {
    return this.currentUser ? this.currentUser.showTrial : true;
  }

  resetPassword(password: string, token: string, user: string): Observable<void> {
    const data = { pass: password, key: token, login: user };
    return this.http.post<void>(this.url + '/snack/v1/setPassword', data);
  }

  canSeeDiscounts() {
    if (!this.utils.isBrowser) {
      return false;
    }
    if (this.canSeeDiscountsValue !== null) {
      return this.canSeeDiscountsValue;
    }
    let sessions = 0;
    let firstAccessDate: Date | null = null;
    let subscribedBefore = false;
    let discountsOverride = false;

    const cookies = this.cookiesService.getAll();
    for (const [name, value] of Object.entries(cookies)) {
      if (name.endsWith('session_counter')) {
        sessions = parseInt(value);
      }
      if (name === 'fad') {
        firstAccessDate = new Date(value);
      }
      if (name === 'sbf') {
        subscribedBefore = true;
      }
      if (name === 'dto') {
        discountsOverride = true;
      }
    }

    if (!firstAccessDate) {
      if (sessions <= 1) {
        firstAccessDate = new Date();
      } else {
        // 6 months ago, to be safe.
        // We know the user has been here before, but we don't know how long ago.
        firstAccessDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 6);
      }
      document.cookie = `fad=${firstAccessDate.toISOString()}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT;`;
    }

    if (!subscribedBefore && this.currentUser && this.currentUser.subscription?.isSubscribed) {
      subscribedBefore = true;
      document.cookie = 'sbf=1; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT;';
    }

    this.canSeeDiscountsValue = (!subscribedBefore && firstAccessDate >= new Date('2023-11-15')) || discountsOverride;
    return this.canSeeDiscountsValue;
  }

  promise: Promise<GoogleAuthResult> | null = null;
  promiseResolve = (value: GoogleAuthResult) => {};

  googleLoginListener = async (event: any) => {
    if (!this.utils.isBrowser) return;
    if (!event.data.type || event.data.type !== 'google-login') return;
    try {
      if (event.data.success) {
        this.http.post<UserInit>(this.apiUrl + `/v1/userInit/`, {}).subscribe((data) => {
          this.autoLogin(data.user);
          this.promiseResolve({ success: true, message: event.data.message });
        });
      } else {
        this.promiseResolve({ success: false, message: event.data.message });
      }
    } catch (error) {
      this.promiseResolve({ success: false, message: 'An unexpected error occurred' });
      throw error;
    } finally {
      window.removeEventListener('message', this.googleLoginListener);
      this.promise = null;
    }
  };

  openGoogleAuth(): Promise<GoogleAuthResult> {
    if (!this.utils.isBrowser) {
      return Promise.resolve({ success: false, message: 'Not in a browser environment' });
    }
    if (!this.promise) {
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        window.addEventListener('message', this.googleLoginListener);
      });
    }

    window.open('/api/v2/googleLogin', '_blank');

    return this.promise;
  }
}
