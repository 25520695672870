import { Directive, Optional, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Input, Host, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, Observable, Subscription } from 'rxjs';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { FormErrorsService } from './form-errors.service';
import { Scavenger } from '../../helpers/scavenger.helper';

@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {

  scavenger = new Scavenger(this);

  @Input() customErrors = {};

  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  subscription: Subscription;
  showErrors = false;

  constructor(
    vcr: ViewContainerRef,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    private errors: FormErrorsService,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : null;
  }

  ngOnInit() {
    let observe = this.control.valueChanges;
    if (this.submit$) {
      observe = merge(observe, this.submit$);
    }
    observe.pipe(this.scavenger.pipe()).subscribe((v) => {
      if (v instanceof Event) {
        this.showErrors = true;
      } else if (v === null) {
        this.showErrors = false;
        this.setError(null);
      }

      const controlErrors = this.control.errors;


      if (controlErrors && this.showErrors) {
        const firstKey = Object.keys(controlErrors)[0];
        // Retrieve the function that will generate the error
        // message for a specific key.
        let getError = this.errors.all[firstKey];
        if (!getError) {
          getError = this.errors.generic;
        }
        const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);
        this.setError(text);
      } else if (this.ref) {
        this.setError(null);
      }
    });
  }

  get control() {
    return this.controlDir.control;
  }

  setError(text: string) {
    if (!this.ref) {
      this.ref = this.container.createComponent(ControlErrorComponent);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {
    this.scavenger.destroy();
  }

}