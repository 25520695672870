

import { Injectable, Inject, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import {Request} from 'express';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LogInterceptor implements HttpInterceptor {

  constructor(@Optional() @Inject(REQUEST) protected request: Request, private utils: UtilsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let serverReq: HttpRequest<any> = req;
    if (!this.utils.isBrowser || !environment.production) {
      console.log(req.url);
    }
    return next.handle(serverReq);
  }
}