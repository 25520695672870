import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Track } from 'src/app/shared/models/tracks-sfx/track.model';
import { Wishlist } from 'src/app/shared/models/wishlist.model';
import { ErrorsService } from '../../services/errors.service';

@Component({
  selector: 'hs-public-wishlist',
  templateUrl: './public-wishlist.component.html',
  styleUrls: ['./public-wishlist.component.scss']
})
export class PublicWishlistComponent implements OnInit {

  id: number;
  tracks: Track[];
  wishlist: Wishlist;

  loading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private wishlistService: WishlistService,
    private errorsService: ErrorsService
  ) { }

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.wishlistService.getPublic(this.id).subscribe(response => {
      this.loading = false;
      this.wishlist = response;
    }, error => {
      this.wishlist = null;
      this.loading = false;
      this.errorsService.track(error);
    });
  }

}
