<div class="modal-content">

  <ng-container *ngIf="isTrack; else subscription">
    <div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <b class="text-center remove-text">
          Our subscription gives you full access to our entire music library.
          <br>It looks like you are adding a single license to this order.
          <br>Do you wish to keep the subscription in the order?
        </b>

        <button (click)="this.removeTracks()" class="btn remove-button my-3 ">
          Yes, keep my subscription
        </button>
        <button (click)="this.removeSubscription()" class="btn remove-sub-button">
          No, update my cart instead
        </button>

      </div>
    </div>
  </ng-container>
  <ng-template #subscription>
    <div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <b lang class="text-center remove-text">
          mixed-cart.title
        </b>
        <button lang (click)="this.removeTracks()" class="btn remove-button my-3 ">
          mixed-cart.yes-update
        </button>
        <button lang (click)="this.removeSubscription()" class="btn remove-sub-button">
          mixed-cart.keep-licenses
        </button>
      </div>
    </div>
  </ng-template>

</div>