import { CartService } from 'src/app/shared/services/cart.service';
import { WebComponentService } from 'src/app/shared/services/web-component.service';
import { ChatService } from './shared/services/chat.service';
import { Component, HostBinding, HostListener, Inject, NgZone, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { User } from './auth/models/user.model';
import { filter } from 'rxjs/operators';
import { InitService } from './shared/services/init.service';
import { ModalService } from './shared/services/modal.service';
import { UtilsService } from './shared/services/utils.service';
import { LocaleService } from './shared/services/locale.service';
import { SEOService } from './shared/services/seo.service';
import { Bugme } from './shared/models/bugme.model';
import { MediaService } from './player/services/media.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ZarazService } from './gtag/gtag.service';
import { DOCUMENT } from '@angular/common';
import { ExperimentsService } from './shared/services/experiments.service';
import { Flags } from './shared/models/flags.model';
import { setTheme } from 'ngx-bootstrap/utils';
import { Scavenger } from './shared/helpers/scavenger.helper';
import { environment } from 'src/environments/environment';
import { StorageService } from './shared/services/storage.service';
import { Subscriptions } from './shared/models/subscriptions.model';
import { Testimonial } from './shared/models/init.model';
import { CartItemFrom } from './shared/models/cart/cart-item.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  scavenger = new Scavenger(this);

  currentUser: User;
  flags: Flags;
  subscriptions: Subscriptions;
  testimonials: Testimonial[];
  playerCollapsed = false;

  userSubscribed = true;
  userAdmin = false;
  userEditor = false;

  headerVariant: string;
  showSearchBar = true;

  bugme: Bugme = null;

  public trackName: string;
  public showItemsInCartPopup = false;

  public year = new Date().getFullYear();

  selectedTab = '';

  showTrial = true;

  /**
   * Disables animations for the entire app until the transition from server has completed.
   */
  @HostBinding('@.disabled')
  public animationsDisabled = true;

  organizationSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Corporation',
    name: 'HookSounds',
    url: 'https://www.hooksounds.com/',
    logo: 'https://www.hooksounds.com/assets/home-images/hooksounds-logo-header.svg',
    image: 'https://www.hooksounds.com/assets/home-images/hooksounds-logo-header.svg',
    email: 'support@hooksounds.com',
    description:
      'Get original royalty free music and sound effects for YouTube, Instagram, Facebook, Podcasts, Radio, TV and any other multimedia content.',
    address: {
      '@type': 'PostalAddress',
      postalCode: '80212',
      streetAddress: '2985 Zenobia St.',
      addressCountry: 'United States',
      addressRegion: 'CO',
      addressLocality: 'Denver',
    },
    telephone: '+1 718 303 2334',
    sameAs: [
      'https://www.youtube.com/channel/UCwghrgZdUGTzjESP_ri0Itg',
      'https://www.instagram.com/HookSounds/',
      'https://www.facebook.com/HookSounds/',
      'https://twitter.com/HookSounds',
    ],
  };

  mobileMaxSize = 992;
  mobile = false;
  menuCollapsed = true;

  constructor(
    public auth: AuthService,
    private router: Router,
    private init: InitService,
    public cart: CartService,
    public mediaService: MediaService,
    public utilsService: UtilsService,
    public locale: LocaleService,
    public seo: SEOService,
    public experimentsService: ExperimentsService,
    public chatService: ChatService,
    public gtag: ZarazService,
    private cartService: CartService,
    private webComponentService: WebComponentService,
    @Optional() @Inject(REQUEST) protected request: any,
    @Inject(DOCUMENT) private document: any
  ) {
    setTheme('bs4');

    this.auth.currentUserSubject.pipe(this.scavenger.pipe()).subscribe((u) => {
      this.currentUser = u;
      if (u) {
        this.showTrial = u.showTrial;
      } else {
        this.userSubscribed = false;
        this.userAdmin = false;
      }
    });
    this.auth.subscription.pipe(this.scavenger.pipe()).subscribe((response) => {
      this.userSubscribed = response?.isSubscribed ?? false;
      this.userAdmin = response?.isAdmin ?? false;
      this.userEditor = response?.isEditor ?? false;
    });

    // Subscribe to route changes to update search bar visibility
    this.router.events
      .pipe(
        this.scavenger.pipe(),
        filter((event: RouterEvent) => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        this.setShowSearchBar(event.url);
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = event.target.innerWidth <= this.mobileMaxSize;
  }

  ngOnInit() {
    if (this.utilsService.isBrowser) {
      // Enable animations after the site is loaded.
      setTimeout(() => {
        this.animationsDisabled = false;
      }, 500);
    } else {
      this.document.documentElement.lang = this.locale.lang;
    }

    this.setUpBugMeBar();
    this.initSEO();

    if (this.utilsService.isBrowser && window.innerWidth <= this.mobileMaxSize) {
      this.mobile = true;
    }
  }

  ngOnDestroy(): void {
    this.scavenger.destroy();
  }

  private initSEO() {
    if (this.utilsService.isBrowser) {
      this.setShowSearchBar(window.location.pathname);
    } else {
      this.setShowSearchBar(this.request.url);
    }

    if (this.utilsService.isBrowser) {
      // Subscribe to route change end for SEO meta tag
      // update and collapse nav menu.
      this.router.events
        .pipe(
          this.scavenger.pipe(),
          filter((event: RouterEvent) => event instanceof NavigationStart)
        )
        .subscribe((event: NavigationStart) => {
          this.seo.updateSEO(event.url);
          this.setShowSearchBar(event.url);
          if (this.hideHeader(event.url)) {
            this.mediaService.stopAndClear();
          }
          this.collapseNav();
        });
    } else if (this.request) {
      this.seo.updateSEO(this.request.url);
    }
  }

  /**
   * Search bar should not be shown in the header in search pages (Experiment variant 2)
   * @private
   */
  private setShowSearchBar(url: string) {
    this.showSearchBar = !(
      url.startsWith('/royalty-free-music') ||
      url.startsWith('/sound-effects') ||
      url.startsWith('/intros')
    );
  }

  /**
   * Initializes the BugMeBar jQuery library with the initial data response.
   */
  private setUpBugMeBar() {
    this.init.data.pipe(this.scavenger.pipe()).subscribe((data) => {
      this.flags = data.flags;
      this.subscriptions = data.subscriptions;
      this.testimonials = data.testimonials;
      if (this.utilsService.isBrowser && window.innerWidth >= 1000 && data.bugMeBar) {
        this.auth.subscription.pipe(this.scavenger.pipe()).subscribe((response) => {
          if (!response?.isSubscribed) {
            const bugme = data.bugMeBar as Bugme;
            if (bugme && bugme?.message) {
              this.bugme = data.bugMeBar;
            }
          } else {
            const surveyData = data.bugMeBar as Bugme;
            (surveyData.message = this.locale.translate('survey.message')),
              (surveyData.primaryColor = '#ffb703'),
              (surveyData.messageColor = '#000'),
              (surveyData.cookieName = 'survey'),
              (this.bugme = surveyData);
          }
        });
      }
    });
  }

  /**
   * Deletes the currently stored user session and redirects to the home screen.
   */
  logout() {
    this.auth.logout().subscribe((_) => {
      this.locale.navigate(['/']);
    });
    this.showTrial = true;
  }

  /**
   * Redirects to the search page with a specific value in the search input field.
   */
  search(value: string) {
    this.collapseNav();
    this.locale.navigate(['/royalty-free-music'], {
      queryParams: { search: value },
    });
  }

  /**
   * Closes the main navigation menu.
   * This should be called on every page change and click outside.
   */
  collapseNav() {
    if (this.utilsService.isBrowser) {
      this.menuCollapsed = true;
    }
  }

  /**
   * Opens Crisp chat.
   */
  openChat() {
    this.chatService.openChat();
  }

  collapse(event: any) {
    this.playerCollapsed = event;
  }

  isPlayerVisible(): boolean {
    return !this.playerCollapsed && this.mediaService.track && !this.mediaService.track.isSoundEffect;
  }

  includePlayer(): boolean {
    return this.utilsService.isBrowser && this.mediaService.track && !this.mediaService.track?.isSoundEffect;
  }

  hideHeader(url: string = null): boolean {
    const routeUrl = new URL(url ?? this.router.url ?? '', environment.frontUrl);
    return (
      this.flags?.freeTrial &&
      (routeUrl.pathname.startsWith('/free-trial') ||
        routeUrl.pathname.startsWith('/' + this.locale.lang + '/free-trial'))
    );
  }

  openFreeTrialModal() {
    this.webComponentService.openFreeTrialModal(this.currentUser, this.flags, this.subscriptions, this.testimonials, CartItemFrom.Header);
  }

  goToCheckout(event) {
    event.preventDefault();
    this.locale.navigate(['/checkout']);
  }

  getAssetFromUrl(url: string): string {
    if (url.includes('/royalty-free-music/')) return 'track';
    if (url.includes('/sound-effects/')) return 'sfx';
    if (url.includes('/intros/')) return 'intro';
    return '';
  }

  openLoginModal() {
    // Implement login modal opening logic
    // this.auth.openLoginModal();
  }

  isActive(path: string): boolean {
    return this.router.url.startsWith(path);
  }
}
