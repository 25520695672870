import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    templateUrl: './control-error.component.html',
    styleUrls: ['./control-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorComponent implements OnInit {

    textValue: any;
    hide = true;

    @Input() set text(value: any) {
        if (value !== this.textValue) {
            this.textValue = value;
            this.hide = !value;
            this.cdr.detectChanges();
        }
    }

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {
    }

}
