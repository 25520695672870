import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { AuthService } from '../services/auth.service';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
/**
 * AuthGuard prevents authenticated users from accessing anonymous routes (Login, SignUp).
 */
export class NoAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private locale: LocaleService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isLoggedInWaitForInit().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          this.locale.navigate(['/']);
          return false;
        }
        return true;
      })
    );
  }
}
