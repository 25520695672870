import { Injectable } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Injectable({
  providedIn: 'root'
})
export class FormErrorsService {

  private errors: any;
  private genericError: any;

  constructor(private locale: LocaleService) { }

  get all() {
    if (!this.errors) {
      this.buildErrors();
    }
    return this.errors;
  }

  get generic(): (error: any) => string {
    if (!this.genericError) {
      this.genericError = (error: any) => this.locale.translate('form-errors.invalid-field');
    }
    return this.genericError;
  }

  private buildErrors() {
    this.errors = {
      required: (error: any) => this.locale.translate('form-errors.required'),
      minlength: ({ requiredLength, actualLength }) => this.locale.translate('form-errors.min-length', { requiredLength }),
      email: (error: any) => this.locale.translate('form-errors.email'),
      ccNumber: (error: any) => this.locale.translate('form-errors.cc-number'),
      mustMatch: (error: any) => this.locale.translate('signup.password-match'),
      invalidPhone: (error: any) => this.locale.translate('form-errors.invalid-phone')
    };
  }

}
