import { User } from '../../auth/models/user.model';

export abstract class ErrorsService {

  public static getError(err) {
    if (err instanceof Error) {
      return err;
    }
    if (typeof err === 'string' || err instanceof String) {
      return new Error(err.toString());
    }
    const newErr = new Error(err?.message || 'unexpected');
    for (const [key, value] of Object.entries(err)) {
      newErr[key] = value;
    }
    return newErr;
  }

  public abstract track(e, tags?: any[]): void;

  public abstract trackAndThrow(e);

  public abstract setUser(user: User);

  public abstract setContext(key: string, data: object);

}