import { Injectable } from "@angular/core";
import { ErrorsService } from "./errors.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
/**
 * Service for storing and retrieving data from localStorage and sessionStorage.
 * This abstracts away the browser's localStorage and sessionStorage API to prevent
 * issues when they are disabled. When localStorage or sessionStorage are disabled,
 * the service will fall back to an in-memory cache.
 */
export class StorageService {

  // These cache objects are used if localStorage is not available.
  // They are not persisted (they don't survive a page reload) but it
  // allows the site to continue working properly.
  private localStorageCache: { [key: string]: string } = {};
  private sessionStorageCache: { [key: string]: string } = {};

  constructor(
    private errorsService: ErrorsService,
  ) {
  }

  /**
   * Returns whether localStorage is available.
   */
  public canStore(): boolean {
    try {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('feature_test', 'yes');
        if (localStorage.getItem('feature_test') === 'yes') {
          localStorage.removeItem('feature_test');
          return true;
        }
      }
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
    }
    return false;
  }

  /**
   * Sets a value in localStorage. If localStorage is not available,
   * the value is stored in the in-memory cache.
   * Objects are stringified before storage, you DO NOT need to call JSON.stringify().
   *
   * @param key The key to store the value under.
   * @param value The value to store.
   */
  public set(key: string, value: object): void {
    let stringData = null;
    try {
      stringData = JSON.stringify(value);
    } catch (e) {
      this.errorsService.setContext('StorageService', {
        key, value
      });
      this.errorsService.track(e);
    }
    try {
      localStorage.setItem(key, stringData);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, store data in cache.
      this.localStorageCache[key] = stringData;
    }
    if (!environment.production) {
      console.log('Current cache (set storage):', this.localStorageCache);
    }
  }

  /**
   * Sets a value in localStorage. If localStorage is not available,
   * the value is stored in the in-memory cache.
   *
   * @param key The key to store the value under.
   * @param value The value to store.
   */
  public setString(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, store data in cache.
      this.localStorageCache[key] = value;
    }
    if (!environment.production) {
      console.log('Current cache (set storage):', this.localStorageCache);
    }
  }

  /**
   * Retrieves a value from localStorage. If localStorage is not available,
   * the value is retrieved from the in-memory cache.
   * Objects are parsed from stringified data, you DO NOT need to call JSON.parse().
   *
   * @param key The key to retrieve the value from.
   */
  public get(key: string): any {
    if (!environment.production) {
      console.warn('Current cache (get storage):', this.localStorageCache);
    }
    let storageData = null;
    try {
      storageData = localStorage.getItem(key);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to retrieve data from cache.
      if (this.localStorageCache.hasOwnProperty(key)) {
        storageData = this.localStorageCache[key];
      } else {
        return null;
      }
    }
    try {
      return JSON.parse(storageData);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      this.errorsService.setContext('StorageService', {
        storageData,
        error: e
      });
      this.errorsService.track(e);
      return null;
    }
  }

  /**
   * Retrieves a string value from localStorage. If localStorage is not available,
   * the value is retrieved from the in-memory cache.
   * Objects are not parsed, it always returns a string.
   *
   * @param key The key to retrieve the string value from.
   */
  public getString(key: string): string {
    if (!environment.production) {
      console.warn('Current cache (get storage):', this.localStorageCache);
    }
    let storageData = null;
    try {
      storageData = localStorage.getItem(key);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to retrieve data from cache.
      if (this.localStorageCache.hasOwnProperty(key)) {
        storageData = this.localStorageCache[key];
      } else {
        return null;
      }
    }
    return storageData;
  }

  /**
   * Returns whether a value is stored in localStorage. If localStorage is not available,
   * the value is retrieved from the in-memory cache.
   *
   * @param key The key to check for.
   */
  public has(key: string): boolean {
    try {
      return localStorage.getItem(key) !== null;
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to retrieve data from cache.
      return this.localStorageCache.hasOwnProperty(key);
    }
  }

  /**
   * Removes a value from localStorage. If localStorage is not available,
   * the value is removed from the in-memory cache.
   *
   * @param key The key to remove.
   */
  public remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to remove data from cache.
      if (this.localStorageCache.hasOwnProperty(key)) {
        delete this.localStorageCache[key];
      }
    }
  }

  /**
   * Sets a value in sessionStorage. If sessionStorage is not available,
   * the value is stored in the in-memory cache.
   * Objects are stringified before storage, you DO NOT need to call JSON.stringify().
   *
   * @param key The key to store the value under.
   * @param value The value to store.
   */
  public setSession(key: string, value: any): void {
    let stringData = null;
    try {
      stringData = JSON.stringify(value);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      this.errorsService.setContext('StorageService', {
        key, value
      });
      this.errorsService.track(e);
    }
    try {
      sessionStorage.setItem(key, stringData);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, store data in cache.
      this.sessionStorageCache[key] = stringData;
    }
  }

  /**
   * Retrieves a value from sessionStorage. If sessionStorage is not available,
   * the value is retrieved from the in-memory cache.
   * Objects are parsed from stringified data, you DO NOT need to call JSON.parse().
   *
   * @param key The key to retrieve the value from.
   */
  public getSession(key: string): any {
    let storageData = null;
    try {
      storageData = sessionStorage.getItem(key);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to retrieve data from cache.
      if (this.sessionStorageCache.hasOwnProperty(key)) {
        storageData = this.sessionStorageCache[key];
      } else {
        return null;
      }
    }
    try {
      return JSON.parse(storageData);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      this.errorsService.setContext('StorageService', {
        storageData,
        error: e
      });
      this.errorsService.track(e);
      return null;
    }
  }

  /**
   * Returns whether a value is stored in sessionStorage. If sessionStorage is not available,
   * the value is retrieved from the in-memory cache.
   *
   * @param key The key to check for.
   */
  public hasSession(key: string): boolean {
    try {
      return sessionStorage.getItem(key) !== null;
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to retrieve data from cache.
      return this.sessionStorageCache.hasOwnProperty(key);
    }
  }

  /**
   * Removes a value from sessionStorage. If sessionStorage is not available,
   * the value is removed from the in-memory cache.
   *
   * @param key The key to remove.
   */
  public removeSession(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
      // Storage access is disabled, attempt to remove data from cache.
      if (this.sessionStorageCache.hasOwnProperty(key)) {
        delete this.sessionStorageCache[key];
      }
    }
  }
}
