import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '../platform/browser/transfer-state';

@Injectable({
  providedIn: 'root'
})
export class ServerCacheService {

  keys: any[] = [];

  constructor(private transferState: TransferState) {}

  get<T>(key: string, defaultValue: T): T {
    this.createKeyIfNull<T>(key);
    return this.transferState.get(this.keys[key], defaultValue);
  }

  set<T>(key: string, value: T) {
    this.createKeyIfNull<T>(key);
    this.transferState.set(this.keys[key], value);
  }

  remove<T>(key: string) {
    this.createKeyIfNull<T>(key);
    this.transferState.remove(this.keys[key]);
  }

  has<T>(key: string): boolean {
    this.createKeyIfNull<T>(key);
    return this.transferState.hasKey(this.keys[key]);
  }

  createKeyIfNull<T>(key: string) {
    if (!this.keys[key]) {
      this.keys[key] = makeStateKey(key);
    }
  }

}
