import { DownloadResponse } from '../models/download-response.model';
import { Track } from '../models/tracks-sfx/track.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from 'src/app/shared/services/service';
import { LocaleService } from "./locale.service";


@Injectable({
  providedIn: 'root'
})
export class TracksService extends Service {

  constructor(
    http: HttpClient,
    private localeService: LocaleService
  ) {
    super(http);
  }

  get(id, slug: string, soundEffect = false): Observable<Track> {
    if (soundEffect) {
      return this.http.get<Track>(this.apiUrl + '/v1/sfx/' + id, { params: { slug, lang: this.localeService.lang } });
    } else {
      return this.http.get<Track>(this.apiUrl + '/v1/tracks/' + id, { params: { slug, lang: this.localeService.lang } });
    }
  }

  getRandom(): Observable<Track[]> {
    return this.http.get<Track[]>(this.apiUrl + '/v1/tracks/random');
  }

  download(trackId: number, isSoundEffect: boolean = false): Observable<DownloadResponse> {
    return this.http.get<DownloadResponse>(this.apiUrl + '/v2/download/' + trackId);
  }

}
