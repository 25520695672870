export class Wave {

  peaks: {};

  drawer = {
    updateSize() {},
    setWidth(width) {}
  };

  drawBuffer() {}

  seekTo(num) {}

  create(options): Wave {
    return this;
  }

  destroy() {}
}
