import { Wishlist } from 'src/app/shared/models/wishlist.model';
import { WishlistResponse } from '../models/wishlist-response.model';
import { Observable, Subject } from 'rxjs';
import { Service } from 'src/app/shared/services/service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WishlistService extends Service {

  public onDelete: Subject<number>;

  constructor(http: HttpClient) {
    super(http);
    this.onDelete = new Subject<number>();
  }

  addToNew(trackId: number, title: string, secret: boolean): Observable<WishlistResponse> {
    return this.http.post<WishlistResponse>(this.url + '/snack/v1/wishlist',
      { title, secret, trackId });
  }

  addToExisting(trackId: number, wishlistId: number): Observable<WishlistResponse> {
    return this.http.post<WishlistResponse>(this.url + '/snack/v1/wishlist/' + wishlistId + '/add', { trackId });
  }

  getAll(): Observable<Array<Wishlist>> {
    return this.http.get<Array<Wishlist>>(this.url + '/snack/v1/wishlist/');
  }

  create(title: string, secret: boolean, description: string) {
    return this.http.post<void>(this.url + '/snack/v1/wishlist',
      { title, secret, description });
  }

  update(wishlistId: number, title: string, description: string, secret: boolean) {
    return this.http.post<void>(this.url + '/snack/v1/wishlist/' + wishlistId,
      { title, description, secret });
  }

  get(id: number): Observable<Wishlist> {
    return this.http.get<Wishlist>(this.url + '/snack/v1/wishlist/' + id);
  }

  getPublic(id: number): Observable<Wishlist> {
    return this.http.get<Wishlist>(this.url + '/snack/v1/wishlist/public/' + id);
  }

  remove(trackId: number, wishlistId: number): Observable<void> {
    return this.http.post<void>(this.url + '/snack/v1/wishlist/' + wishlistId + '/remove', { trackId });
  }

  delete(wishlistId: number) {
    return this.http.post<void>(this.url + '/snack/v1/wishlist/' + wishlistId + '/delete', {});
  }
}
