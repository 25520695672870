import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { LocaleService } from '../services/locale.service';
import { User } from '../../auth/models/user.model';
import { Scavenger } from '../helpers/scavenger.helper';

@Component({
  selector: 'hs-user-dropdown',
  template: `
    <div *ngIf="user" class="user-dropdown" [class.dark]="dark">
      <button class="user-menu">
        <img class="avatar" [src]="user.avatar" [alt]="user.email" />
        <img class="chevron" src="assets/shared/chevron-down.svg" alt="" aria-hidden="true" />
      </button>
      <div class="user-content">
        <div class="content-box">
          <a class="dropdown-item" langLink="/my-profile/user-profile">{{ 'common.profile' | lang }}</a>
          <hr />
          <a class="dropdown-item" langLink="/my-profile/wishlists">{{ 'profile.wishlist' | lang }}</a>
          <hr />
          <ng-container *ngIf="user.affiliate?.isAffiliate">
            <a class="dropdown-item" langLink="/affiliate-profile/urls">{{ 'common.affiliate' | lang }}</a>
            <hr />
          </ng-container>
          <button class="logout" (click)="logout.emit()">{{ 'actions.log-out' | lang }}</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .user-dropdown {
        position: relative;
        display: inline-block;
        font-size: 14px;
      }

      .user-dropdown hr {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .user-menu {
        display: flex;
        align-items: center;
        width: 60px;
        height: 32px;
        border: 1px solid #d7d7d7;
        box-shadow: 0px 0px 3px 0px #0000001a;
        background-color: white;
        border-radius: 100px;
        padding-left: 4px;
        padding-right: 8px;
        gap: 4px;
      }

      .dark .user-menu {
        background-color: #1a1b1e;
        border-color: #0e0f10;
      }

      .user-menu .avatar {
        width: 24px;
        height: 24px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 1px solid #d7d7d7;
        background: #f5f5f5;
        flex-shrink: 0;
      }

      .user-menu .chevron {
        filter: brightness(0.6);
        transition: transform 0.3s;
      }

      .dark .user-menu .chevron {
        filter: brightness(1);
      }

      .user-content {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        right: 0;
        z-index: 1;
      }

      .content-box {
        background-color: white;
        border: 1px solid #d7d7d7;
        box-shadow: 0px 0px 3px 0px #0000001a;
        min-width: 160px;
        padding: 12px 16px;
        border-radius: 8px;
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      .user-content .dropdown-item {
        opacity: 0.7;
        display: block;
        max-width: 240px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0.25rem 1.5rem;
        margin: 0;
      }

      .user-content .logout {
        border: none;
        background-color: transparent;
        box-shadow: none;
        color: #cc0000;
        font-weight: 500;
      }

      .logout button {
        margin: 0;
        padding: 0;
      }

      .user-dropdown:hover .user-content {
        visibility: visible;
        opacity: 1;
      }

      .user-dropdown:hover .chevron {
        transform: rotate(180deg);
      }

      a:hover {
        background-color: white;
      }
    `,
  ],
})
export class UserDropdownComponent {
  @Input() dark = false;
  @Input() user: User;
  @Output() logout = new EventEmitter<void>();

  constructor() {}
}
