<div [@fadeIn]="'in'" *ngIf="spinner.show" class="overlay" [style.background-color]="spinner.bdColor"
  [style.z-index]="spinner.zIndex" [style.position]="spinner.fullScreen ? 'fixed' : 'absolute'">
  <div class='main-container d-flex flex-column align-items-center' [class.box]="spinner.fullScreen" [class.only-spinner]="!content?.childNodes?.length && spinner.fullScreen">
    <div *ngIf="!template" [class]="spinner.class" [style.color]="spinner.color">
      <div *ngFor="let index of spinner.divArray"></div>
    </div>
    <div *ngIf="template" [innerHTML]="template | safe:'html'"></div>
    <div class="loading-text" [style.z-index]="spinner.zIndex" #content><ng-content></ng-content></div>
  </div>
</div>
