import { Subscriptions } from '../../models/subscriptions.model';
import { InitService } from '../../services/init.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'src/app/shared/models/subscriptions.model';

@Component({
  selector: 'hs-select-subscription',
  templateUrl: './select-subscription.component.html',
  styleUrls: ['./select-subscription.component.scss']
})
export class SelectSubscriptionComponent implements OnInit {


  @Input() type;
  @Input() monthly: boolean;
  @Input() lightBackground = true;
  @Input() subscriptions: Subscriptions;
  @Input() modal = false;

  @Input() selected;

  @Input() selectedSubscription: Subscription;
  @Output() selectedSubscriptionChange = new EventEmitter<Subscription>();


  constructor(private init: InitService) { }

  ngOnInit(): void {}

  setSelected(subscription: Subscription) {
    this.selectedSubscription = subscription;
    this.selectedSubscriptionChange.emit(subscription);
  }

  setSelectedSubscription() {
    this.selected = true;
  }

}
