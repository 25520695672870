import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { Wishlist } from 'src/app/shared/models/wishlist.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hs-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  wishlist: Wishlist;
  loading = false;

  constructor(public bsModalRef: BsModalRef, private wishlistService: WishlistService) { }

  ngOnInit(): void {
  }

  delete(wishlist: any) {
    this.loading = true;
    this.wishlistService.delete(wishlist.id).subscribe(response => {
      this.loading = false;
      this.wishlistService.onDelete.next(wishlist.id);
      this.bsModalRef.hide();
    });
  }
}
