import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { Observable } from 'rxjs';
// import { printSubscribers, setup, track } from './app/shared/helpers/observable-profiler';


const domains = ['hooksounds.com', 'www.hooksounds.com', 'hs.snack.onl', 'hksns.dev', 'www.hksns.dev', 'localhost'];
const host = window?.location?.hostname ?? null;
if (!host || domains.includes(host)) {
  if (environment.production) {
    enableProdMode();
  }
  // Uncomment code to enable observable-profiler
  // setup(Observable);
  platformBrowserDynamic().bootstrapModule(AppModule);
  //   .then(ref => {
  //   track(true);
  //   (window as any).stopProfiler = () => {
  //     try {
  //       ref.destroy();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     const leaked = track(false);
  //     printSubscribers({
  //       subscribers: leaked,
  //     });
  //   }
  // });
}
