import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private responseCache = new Map();
  private translations: any = {};

  constructor(
    private utils: UtilsService,
    private httpClient: HttpClient,
    private router: Router,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  loadTranslations(): Observable<any> {
    return this.httpClient
      .get(environment.frontUrl + 'assets/i18n/' + this.lang + '.json', { headers: this.headers })
      .pipe(
        map((response: any) => {
          this.translations = response;
          return response;
        })
      );
  }

  get locale(): any {
    let path: string = '';
    if (this.utils.isBrowser) {
      path = window.location.pathname;
    } else if (this.request) {
      path = this.request.url;
    }

    let lang = 'en';
    if (path?.startsWith('/es/') || path === '/es') {
      lang = 'es';
    }
    if (path?.startsWith('/de/') || path === '/de') {
      lang = 'de';
    }
    if (path?.startsWith('/pt-br/') || path === '/pt-br') {
      lang = 'pt-br';
    }
    return {
      lang,
    };
  }

  get lang(): string {
    return this.locale.lang;
  }

  get langs(): string[] {
    return ['en', 'de', 'es', 'pt-br'];
  }

  /**
   * Returns the translated help home slug.
   * @param lang By default the function uses the current language, this can be overriden with this param.
   * @returns The help slug for the current language.
   */
  getHelpSlug(lang: string = null) {
    if (!lang) {
      lang = this.lang;
    }
    if (lang === 'de') {
      return 'hilfe';
    } else if (lang === 'es') {
      return 'ayuda';
    } else if (lang === 'pt-br') {
      return 'ajuda';
    }
    return 'help';
  }

  /**
   * Returns the path prefix for the current language with slashes on start and end. en -> /, es -> /es/
   */
  getLangPathAbsolute() {
    return this.lang === 'en' ? '/' : '/' + this.lang + '/';
  }

  /**
   * Returns the site URL and base path depending on the current language.
   */
  getSiteUrl() {
    if (this.lang === 'en') {
      return 'https://www.hooksounds.com/';
    } else {
      return 'https://www.hooksounds.com/' + this.lang + '/';
    }
  }

  /**
   * Prepends the current language to the supplied path.
   */
  getLangLink(path: string) {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }
    if (!path.endsWith('/')) {
      path = path + '/';
    }
    if (this.lang !== 'en') {
      path = '/' + this.lang + path;
    }
    return path;
  }

  /**
   * Prepends the current language and domain to the supplied path.
   */
  getLangFullLink(path: string) {
    let langPath = this.getLangLink(path);
    if (langPath.startsWith('/')) {
      langPath = langPath.substr(1);
    }
    return environment.url + langPath;
  }

  /* Returns the Paddle languages*/

  getLangPaddle() {
    if (this.lang === 'pt-br') {
      return 'pt';
    }
    return this.lang;
  }

  translate(key: string, params: any = null) {
    let value = this.getValue(key, this.translations, '.');
    if (!environment.production && !value) {
      console.warn('Missing translation for key: ' + key);
      return key;
    }
    if (!value) {
      return '';
    }
    if (params) {
      return this.handleParams(value, params);
    }
    return value;
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    commands[0] = this.getLangLink(commands[0]);
    return this.router.navigate(commands, extras);
  }

  private getValue(key: string, data: { [key: string]: any }, keySeparator: string): string | any | null {
    if (data) {
      if (keySeparator) {
        return key.split(keySeparator).reduce((acc, cur) => ((acc && acc[cur]) != null ? acc[cur] : null), data);
      }
      return data[key] != null ? data[key] : null;
    }
    return null;
  }

  private handleParams(value: string, params: any): string {
    const replacedValue = value.replace(/{{\s?([^{}\s]*)\s?}}/g, (substring: string, parsedKey: string) => {
      const replacer = params[parsedKey];
      return replacer !== undefined ? replacer : substring;
    });
    return replacedValue;
  }

  price(price: number | null, decimals = 2): string {
    if (price == null) {
      return '';
    }
    let locale = this.lang == 'pt-br' ? 'pt-BR' : this.lang;
    if (locale === 'es') {
      locale = 'es-AR';
    }
    let formattingOptions = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    };
    return new Intl.NumberFormat(locale, formattingOptions).format(price);
  }
}
