import { Injectable } from '@angular/core';
import { Wave } from './wave';
import { UtilsService } from '../shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class WaveService {

  private wave: any = null;

  constructor(private utils: UtilsService) {
  }

  async get(): Promise<Wave> {
    if (this.utils.isBrowser) {
      if (!this.wave) {
        const { WaveSurfer } = await import('./wavesurfer/wavesurfer');
        this.wave = WaveSurfer;
      }
      return this.wave;
    }
    return new Wave();
  }

}
