<ngx-json-ld [json]="organizationSchema"></ngx-json-ld>

<!-- <hs-bugme *ngIf="!hideHeader()" [data]="bugme"></hs-bugme> -->

<ng-container *ngIf="!hideHeader()">
  <nav class="navbar navbar-white">
    <div class="main bottom-border">
      <div class="container-ld">
        <a class="logo-header" href="{{ '/' | langHref }}">
          <img
            class="full-logo"
            alt="Royalty Free Music"
            title="HookSounds"
            src="/assets/home-images/hooksounds-logo-header-dark.svg"
            width="289"
            height="36"
          />
        </a>

        <ul class="navbar-nav">
          <!-- Music Dropdown -->
          <li class="nav-item">
            <a class="nav-link" href="{{ '/royalty-free-music/' | langHref }}" (click)="gtag.click('header_music')">
              {{ 'header.browse.music' | lang }}
            </a>
          </li>

          <!-- Sound effects dropdown -->
          <li class="nav-item">
            <a class="nav-link" href="{{ '/sound-effects/' | langHref }}" (click)="gtag.click('header_sound_effects')">
              {{ 'header.sfx' | lang }}
            </a>
          </li>

          <!-- Intros & Outros -->
          <li class="nav-item">
            <a class="nav-link" href="{{ '/intros/' | langHref }}" (click)="gtag.click('header_intros_outros')">
              {{ 'header.intros' | lang }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://shortcap.hooksounds.com"
              target="_blank"
              (click)="gtag.click('header_shortcap')"
              >ShortCap</a
            >
          </li>
          <li class="nav-item" *ngIf="!currentUser?.subscription?.hasTracksAccess">
            <a class="nav-link pink" href="{{ '/subscriptions/' | langHref }}">
              {{ 'common.pricing' | lang }}
            </a>
          </li>
        </ul>

        <ul class="navbar-nav right-elements">
          <!-- Get Full Access & Login buttons -->
          <li class="right-element" *ngIf="!currentUser?.subscription?.hasTracksAccess">
            <button *ngIf="flags?.freeTrial && showTrial" class="nav-link get-access" (click)="openFreeTrialModal()">
              {{ 'free-trial.get-started' | lang }}
            </button>
            <a
              *ngIf="!flags?.freeTrial || !showTrial"
              class="nav-link get-access"
              href="{{ '/subscriptions/' | langHref }}"
            >
              {{ 'subscriptions.get-full-access' | lang }}
            </a>
          </li>

          <!-- User dropdown -->
          <li class="auth" [class.right-element]="cart.totalItems > 0" *ngIf="currentUser">
            <hs-user-dropdown [user]="currentUser" (logout)="logout()"></hs-user-dropdown>
          </li>

          <!-- Login button -->
          <li class="auth" [class.right-element]="cart.totalItems > 0" *ngIf="!currentUser">
            <a class="nav-link" langLink="/login/">{{ 'actions.log-in' | lang }}</a>
          </li>

          <!-- Cart icon -->
          <li class="cart" *ngIf="cart.totalItems > 0">
            <a class="nav-link checkout" href="{{ '/checkout/' | langHref }}">
              <img
                alt="{{ 'cart.title' | lang }}"
                src="/assets/home-images/cart.svg"
                loading="lazy"
                width="21"
                height="19"
              />
              {{ cart.totalItems }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="mobile bottom-border">
      <a class="logo" href="{{ '/' | langHref }}">
        <img alt="Royalty Free Music" title="HookSounds" src="/assets/home-images/hooksounds-logo-header-dark.svg" />
      </a>
      <ng-container *ngIf="!currentUser?.subscription?.hasTracksAccess">
        <button *ngIf="flags?.freeTrial && showTrial" class="nav-link get-access" (click)="openFreeTrialModal()">
          {{ 'free-trial.get-started' | lang }}
        </button>
        <a
          *ngIf="!flags?.freeTrial || !showTrial"
          class="nav-link get-access"
          href="{{ '/subscriptions/' | langHref }}"
        >
          {{ 'subscriptions.get-full-access' | lang }}
        </a>
      </ng-container>
      <a class="icon" href="{{ '/checkout/' | langHref }}" *ngIf="cart.totalItems > 0">
        <img
          alt="{{ 'common.cart' | lang }}"
          class="img_charleston-grey"
          src="/assets/home-images/cart.svg"
          loading="lazy"
          height="16"
          width="16"
        />
      </a>
      <button class="icon hamburger" (click)="menuCollapsed = false" [attr.aria-label]="'actions.open-menu' | lang">
        <img
          [alt]="'actions.open-menu' | lang"
          src="/assets/shared/hamburger.svg"
          loading="lazy"
          width="16"
          height="10"
        />
      </button>
    </div>

    <div *ngIf="!menuCollapsed" class="overlay" (click)="menuCollapsed = true"></div>
    <!-- Mobile Menu Content -->
    <div *ngIf="!menuCollapsed" class="mobile-menu">
      <div class="close-overlay">
        <button (click)="menuCollapsed = true" [attr.aria-label]="'actions.close-menu' | lang">
          <img src="/assets/shared/close-cross.svg" [alt]="'actions.close-menu' | lang" />
        </button>
      </div>

      <nav>
        <div class="auth">
          <div class="spacer"></div>
          <ng-container *ngIf="!currentUser">
            <a langLink="/login/">
              {{ 'actions.log-in' | lang }}
            </a>
          </ng-container>
          <ng-container *ngIf="currentUser">
            <hs-user-dropdown [user]="currentUser" [dark]="true" (logout)="logout()"></hs-user-dropdown>
          </ng-container>
        </div>

        <ul>
          <li>
            <a href="{{ '/royalty-free-music/' | langHref }}" (click)="gtag.click('header_music')">
              {{ 'header.browse.music' | lang }}
            </a>
          </li>
          <li>
            <a href="{{ '/sound-effects/' | langHref }}" (click)="gtag.click('header_sound_effects')">
              {{ 'common.sound-effects' | lang }}
            </a>
          </li>
          <li>
            <a href="{{ '/intros/' | langHref }}" (click)="gtag.click('header_intros_outros')">
              {{ 'common.intros-outros' | lang }}
            </a>
          </li>
          <li>
            <a href="https://shortcap.hooksounds.com" target="_blank" (click)="gtag.click('header_shortcap')">
              ShortCap
            </a>
          </li>
          <li *ngIf="currentUser">
            <a
              langLink="/my-profile/wishlists/"
              [class.active]="isActive('/my-profile/wishlists/')"
              (click)="gtag.click('header_wishlists')"
            >
              {{ 'profile.wishlist' | lang }}
            </a>
          </li>
          <ng-container *ngIf="!currentUser?.subscription?.hasTracksAccess">
            <li>
              <a href="{{ '/subscriptions/' | langHref }}">
                {{ 'common.pricing' | lang }}
              </a>
            </li>
            <li class="button-note">
              <ng-container *ngIf="!flags?.freeTrial || !showTrial">
                <a class="btn-pink-big" href="{{ '/subscriptions/' | langHref }}">
                  {{ 'subscriptions.get-full-access' | lang }}
                </a>
              </ng-container>
              <ng-container *ngIf="flags?.freeTrial && showTrial">
                <button class="btn-pink-big" (click)="openFreeTrialModal()">
                  {{ 'free-trial.get-started' | lang }}
                </button>
              </ng-container>
              <p>{{ 'home.no-copyright' | lang }}</p>
            </li>
            <li></li>
          </ng-container>
        </ul>
      </nav>
    </div>
  </nav>
</ng-container>

<router-outlet></router-outlet>

<div class="spacer flex-grow-1"></div>

<!-- Footer -->
<footer *ngIf="!hideHeader()" class="page-footer footer">
  <!-- Footer Links -->
  <div class="container-ld">
    <!-- Grid row -->
    <div class="row" style="vertical-align: auto">
      <!-- Grid column -->
      <div class="col-sd-4">
        <img
          alt="HookSounds logo"
          title="HookSounds logo"
          class="logo-footer"
          width="289"
          height="36"
          src="/assets/home-images/hooksounds-logo-header-dark.svg"
          loading="lazy"
        />

        <p>{{ 'footer.sub-title' | lang: { year: year } }}</p>

        <a class="phone" href="tel:{{ 'common.contact-phone' | lang }}">{{ 'common.contact-phone' | lang }}</a>

        <p class="mt-3">{{ 'footer.connect-with-us' | lang }}</p>

        <a class="social" rel="”noopener”" href="https://twitter.com/HookSounds" target="_blank">
          <img alt="Twitter" title="Twitter" src="/assets/shared/twitter.svg" loading="lazy" width="22" height="18" />
        </a>
        <a class="social" rel="”noopener”" href="https://www.facebook.com/HookSounds/" target="_blank">
          <img
            alt="Facebook"
            title="Facebook"
            src="/assets/shared/facebook.svg"
            loading="lazy"
            width="10"
            height="22"
          />
        </a>
        <a
          class="social"
          rel="”noopener”"
          href="https://www.youtube.com/channel/UCwghrgZdUGTzjESP_ri0Itg"
          target="_blank"
        >
          <img alt="YouTube" title="YouTube" src="/assets/shared/youtube.svg" loading="lazy" width="22" height="22" />
        </a>
        <a class="social" rel="”noopener”" href="https://www.instagram.com/HookSounds/" target="_blank">
          <img
            alt="Instagram"
            title="Instagram"
            src="/assets/shared/instagram.svg"
            loading="lazy"
            width="23"
            height="23"
          />
        </a>
      </div>

      <div class="col-sd col-6">
        <h5 class="footer-title">{{ 'footer.about.title' | lang }}</h5>
        <ul class="list-unstyled">
          <li>
            <a class="link" langLink="/about">{{ 'footer.company.about-us' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/about/connect">{{ 'footer.about.connect' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/about/copyright">{{ 'footer.about.copyright' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/about/how-we-do-it">{{ 'footer.about.how-we-do-it' | lang }}</a>
          </li>
        </ul>
      </div>

      <!-- Grid column -->
      <div class="col-sd col-6">
        <!-- Links -->
        <h5 class="footer-title">{{ 'footer.company.title' | lang }}</h5>
        <ul class="list-unstyled">
          <li>
            <a class="link" href="{{ '/royalty-free-music/' | langHref }}">{{
              'footer.company.royalty-free-music' | lang
            }}</a>
          </li>
          <li>
            <a class="link" href="{{ '/sound-effects/' | langHref }}">{{ 'common.sound-effects-short' | lang }}</a>
          </li>
          <li>
            <a class="link" href="{{ '/intros/' | langHref }}">{{ 'common.intros-outros-short' | lang }}</a>
          </li>
          <li>
            <a class="link" href="{{ '/subscriptions/' | langHref }}">{{ 'common.pricing' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="{{ 'path.privacy-policy' | lang }}">{{
              'footer.company.privacy-policy' | lang
            }}</a>
          </li>
          <li>
            <a class="link" langLink="{{ 'path.terms-of-service' | lang }}">{{ 'footer.company.tos' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/{{ locale.getHelpSlug() }}">{{ 'footer.company.help' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/blog">{{ 'footer.company.blog' | lang }}</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-sd col-6">
        <!-- Links -->
        <h5 class="footer-title">{{ 'footer.plans.title' | lang }}</h5>

        <ul class="list-unstyled">
          <li>
            <a class="link" href="{{ '/premium/' | langHref }}">{{ 'subscriptions.premium' | lang }}</a>
          </li>
          <li>
            <a class="link" href="{{ '/business/' | langHref }}">{{ 'subscriptions.business' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/in-store-music">{{ 'footer.plans.in-store-music' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/education">{{ 'footer.plans.education-program' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/nonprofit">{{ 'footer.plans.nonprofit-program' | lang }}</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->
      <div class="col-sd col-6">
        <!-- Links -->
        <h5 class="footer-title">{{ 'footer.become.title' | lang }}</h5>

        <ul class="list-unstyled">
          <li>
            <a class="link" langLink="/affiliates">{{ 'footer.become.affiliate' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/partnerships">{{ 'footer.become.partner' | lang }}</a>
          </li>
          <li>
            <a class="link" langLink="/sponsorship-program">{{ 'footer.become.sponsor' | lang }}</a>
          </li>
        </ul>

        <h5 class="footer-title">{{ 'footer.contact.title' | lang }}</h5>
        <ul class="list-unstyled">
          <li>
            <a class="link" langLink="/contact">{{ 'actions.contact-us' | lang }}</a>
          </li>
        </ul>
      </div>

      <!-- Grid column -->
      <div class="col-sd col-6">
        <!-- Links -->
        <h5 class="footer-title">{{ 'footer.languages.title' | lang }}</h5>

        <ul class="list-unstyled">
          <li>
            <a [href]="seo.langUrls?.en ? seo.langUrls?.en : seo.fallbackLangUrls.en" class="link">
              <img
                alt="UK flag"
                class="flag"
                src="/assets/home-images/us-flag.svg"
                loading="lazy"
                width="16"
                height="11"
              />
              {{ 'footer.languages.english' | lang }}
            </a>
          </li>
          <li>
            <a [href]="seo.langUrls?.es ? seo.langUrls?.es : seo.fallbackLangUrls.es" class="link">
              <img
                alt="Spain flag"
                title="Spanish"
                class="flag"
                src="/assets/home-images/spain-flag.svg"
                loading="lazy"
                width="16"
                height="11"
              />
              {{ 'footer.languages.spanish' | lang }}
            </a>
          </li>
          <li>
            <a [href]="seo.langUrls?.de ? seo.langUrls?.de : seo.fallbackLangUrls.de" class="link">
              <img
                alt="Germany flag"
                title="German"
                class="flag"
                src="/assets/home-images/germany-flag.svg"
                loading="lazy"
                width="16"
                height="11"
              />
              {{ 'footer.languages.german' | lang }}
            </a>
          </li>
          <li>
            <a
              [href]="seo.langUrls && seo.langUrls['pt-br'] ? seo.langUrls['pt-br'] : seo.fallbackLangUrls['pt-br']"
              class="link"
            >
              <img
                alt="Brazil flag"
                title="Portuguese (Brazil)"
                class="flag"
                src="/assets/home-images/brazil-flag.svg"
                loading="lazy"
                width="16"
                height="11"
              />
              {{ 'footer.languages.portuguese' | lang }}
            </a>
          </li>
        </ul>

        <img
          alt="HookSounds Footer Logo"
          title="HookSounds Footer Logo"
          class="footer-logo"
          src="/assets/home-images/hooksounds-logo-footer.svg"
          loading="lazy"
          width="181"
          height="159"
        />
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
  <div class="border-top-copyright d-flex justify-content-center">
    {{ 'footer.copyright' | lang: { year: year } }}
  </div>
</footer>
<!-- Footer -->

<button
  *ngIf="!chatService.loading"
  (click)="openChat()"
  class="chat-button"
  [class.playing]="isPlayerVisible()"
  aria-label="Support chat"
></button>
<button
  *ngIf="chatService.loading"
  class="chat-button loading"
  [class.playing]="isPlayerVisible()"
  aria-label="Loading support chat"
>
  <hs-spinny-spin [white]="true" size="2"></hs-spinny-spin>
</button>

<hs-player *ngIf="includePlayer()" (collapsedChange)="collapse($event)" class="fixed-bottom"></hs-player>
