import { Injectable } from '@angular/core';

import { CookiesService } from '../cookies.service';
import { CookiesOptions } from '../cookies-options';
import { CookiesOptionsService } from '../cookies-options.service';
import { isBlank, isString, mergeOptions, safeDecodeURIComponent } from '../utils';

@Injectable()
export class BrowserCookiesService extends CookiesService {
  private cookieCache: string = '';

  get cookieString(): string {
    try {
      return document.cookie || '';
    } catch (e) {
      return this.cookieCache || '';
    }
  }

  set cookieString(val: string) {
    try {
      document.cookie = val;
    } catch (e) {
      this.cookieCache = val;
    }
  }

  constructor(cookiesOptions: CookiesOptionsService) {
    super(cookiesOptions);
  }

  protected cookiesReader(): { [key: string]: any } {
    const lastCookies = {};
    const currentCookieString = this.cookieString;
    if (currentCookieString !== '') {
      const cookiesArray = currentCookieString.split('; ');
      cookiesArray.forEach((cookie) => {
        const index = cookie.indexOf('=');
        if (index > 0) {
          // ignore nameless cookies
          const name = safeDecodeURIComponent(cookie.substring(0, index));
          if (isBlank((lastCookies as any)[name])) {
            (lastCookies as any)[name] = safeDecodeURIComponent(cookie.substring(index + 1));
          }
        }
      });
    }
    return lastCookies;
  }

  protected cookiesWriter(): (name: string, value: string | undefined, options?: CookiesOptions) => void {
    return (name: string, value: string | undefined, options?: CookiesOptions) => {
      if (value === undefined) {
        options.expires = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
        document.cookie = this.buildCookieString(name, '', options);
        return;
      }
      this.cookieString = this.buildCookieString(name, value, options);
    };
  }

  private buildCookieString(name: string, value: string | undefined, options?: CookiesOptions): string {
    const opts: CookiesOptions = mergeOptions(this.options, options);
    let expires: any = opts.expires;
    if (isBlank(value)) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      value = '';
    }
    if (isString(expires)) {
      expires = new Date(expires);
    }
    let str = encodeURIComponent(name) + '=' + encodeURIComponent(value as string);
    str += opts.path ? ';path=' + opts.path : '';
    str += opts.domain ? ';domain=' + opts.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += opts.secure ? ';secure' : '';
    const cookiesLength = str.length + 1;
    if (cookiesLength > 4096) {
      console.log(`Cookie \'${name}\' possibly not set or overflowed because it was too
      large (${cookiesLength} > 4096 bytes)!`);
    }
    return str;
  }
}
