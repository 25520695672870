import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from './components/ngx-spinner/ngx-spinner.service';

@Component({
  selector: 'hs-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() text: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() fullScreen = false;
  @Input() background = 'rgba(255, 255, 255, 0.8)';

  private showSpinner: boolean;
  @Input() set show(value: boolean) {
    this.showSpinner = value;
    this.updateSpinnerVisibility();
  }
  get show(): boolean {
    return this.showSpinner;
  }

  /**
   * Ngx-spinner needs a unique name (to handle each spinner separately)
   * so we generate a random string.
   */
  @Input() name = Math.random().toString(36);

  constructor(private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    if (this.fullScreen && this.background === 'rgba(255, 255, 255, 0.8)') {
      this.background = 'rgba(220, 220, 220, 0.8)';
    }
  }

  private updateSpinnerVisibility() {
    if (this.show) {
      this.spinnerService.show(this.name);
    } else {
      this.spinnerService.hide(this.name);
    }
  }

}
