import { Pipe, PipeTransform } from '@angular/core';
import { ImageSrc } from '../models/image-src.model';

@Pipe({
  name: 'srcset',
  pure: true
})
export class SrcsetPipe implements PipeTransform {

  constructor() { }

  transform(srcset: ImageSrc[], maxWidth?: number): string | null {
    if (!Array.isArray(srcset) || srcset.length === 0) {
      return '';
    }
    let result = '';
    let fallback: ImageSrc = null;
    srcset?.forEach(img => {
      if (fallback == null && img.width > 200 && img.width < 600) {
        fallback = img;
      }
      if (!maxWidth || img.width <= maxWidth) {
        result = `${img.url} ${img.width}w,` + result;
      }
    });

    if (!fallback && srcset && srcset.length > 0) {
      fallback = srcset[srcset.length - 1];
    }

    // Remove trailing comma
    result = result.replace(/,\s*$/, '');

    if (!result && fallback) {
      result = `${fallback.url} ${fallback.width}w`;
    }

    if (result) {
      return result;
    }
    if (fallback) {
      return fallback.url;
    }
    return '';
  }
}
