<div class="d-flex margin-bottom" [class.light-background]="lightBackground" [class.large]="large" [class.medium]="medium">
    <label class="toggle-option margin-right-option" [class.selected]="!optionSelected">{{optionLeft}}</label>
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input custom-control-input " id="{{id}}"
            (change)="setSelected($event)" [class.pink]="selectedPink" [checked]="optionSelected">
        <label class="custom-control-label toggle-option padding-left-option" [class.selected]="optionSelected"
            for="{{id}}">{{optionRight}}</label>
    </div>
</div>

