<div class="modal-content">
  <div class="modal-header text-center">
    <h4 lang class="modal-title">wishlist.delete-title</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="padding-body relative">

      <div class="d-flex flex-column align-items-center justify-content-center mb-4">
        <h2 lang class="warning">wishlist.delete-warning</h2>
        <button class="btn save" (click)="delete(wishlist)" lang>wishlist.delete</button>
      </div>


      <hs-spinner [show]="loading"></hs-spinner>


    </div>
  </div>
</div>
