import { OnDestroy } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";

export class Scavenger {
  private readonly destroyed =  new ReplaySubject<any>(1);

  public constructor(caller: OnDestroy) {
    
  }

  public pipe<T>() {
    return takeUntil<T>(this.destroyed);
  }

  public destroy() {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}