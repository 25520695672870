import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { CookiesService } from 'src/app/cookies/cookies.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable()
/**
 * ErrorInterceptor intercepts HTTP responses from the API to check if there were any errors.
 * If there is a 403 Forbidden response the user is automatically logged out of the application,
 * all other errors are re-thrown up to the calling service.
 */
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private cookiesService: CookiesService,
    private utils: UtilsService,
    private router: Router,
    private locale: LocaleService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 403) {
          // Auto logout if 403 response returned from API.
          if (!this.utils.isBrowser) {
            this.cookiesService.remove('hsauth');
          }
          this.locale.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
        }
        return throwError(err);
      })
    );
  }
}
