import { ImageSrc } from '../image-src.model';
export class CartItem {
  public name: string;
  public id: number;
  public price: string;
  public regularPrice?: string;
  public priceId?: number;
  public srcset?: Array<ImageSrc>;
  public shortName?: string;
  public variant?: string;
  public category?: string;
  public isSubscription: boolean;
  public isMonthly?: boolean;
  public isNeverExpiring?: boolean;
  public from?: CartItemFrom;
}

export enum CartItemFrom {
  UNKNOWN = 'unknown',
  HomeHero = 'home-hero',
  Header = 'header',
  MobileMenu = 'mobile-menu',
  LicenseModal = 'license-modal',
  SubscriptionModal = 'subscription-modal',
  TrackDetail = 'track-detail',
  IntroDetail = 'intro-detail',
  SfxDetail = 'sfx-detail',
  SearchHeroTrack = 'search-hero-track',
  SearchHeroIntro = 'search-hero-intro',
  SearchHeroSfx = 'search-hero-sfx',
  Licensing = 'licensing',
  Business = 'business',
  Premium = 'premium',
  Collections = 'collections',
  CollectionDetail = 'collection-detail',
  LandingPage = 'landing-page',
  TrialPage = 'trial-page',
  ProfilePage = 'profile-page',
}
