import { Pipe, PipeTransform } from '@angular/core';
import { ImageSrc } from '../models/image-src.model';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'price',
  pure: true
})
export class PricePipe implements PipeTransform {

  constructor(
    private locale: LocaleService
  ) { }

  transform(n: number|string|null, decimals = 2): string | null {
    if (!n) {
      return '';
    }
    if (typeof n === 'string') {
      n = parseFloat(n);
    }
    return this.locale.price(n, decimals);
  }
}