export const environment = {
  tag: 'production',
  production: true,
  url: 'https://www.hooksounds.com/', // With trailing slash
  localUrl: 'http://localhost:5000/',
  frontUrl: 'https://www.hooksounds.com/',
  sentry: {
    browserDsn: 'https://d0032e53c16944aea255cba421c33492@o512250.ingest.sentry.io/5693147',
    browserSampleRate: 0.1,
    serverDsn: 'https://7254c2278c054387b1c8d96499dc562d@o512250.ingest.sentry.io/5650720',
    serverSampleRate: 0.05
  },
  gtag: 'UA-78898400-1',
  adWordsId: 'AW-1058848674',
  adWordsFreeConversion: 'ARwHCIynluoBEKL_8vgD',
  adWordsSongsConversion: 'kJCkCMPUkOQBEKL_8vgD',
  adWordsSubscriptionsConversion: 'JOCSCKasluoBEKL_8vgD',
  distFolder: '/var/www/public',
  frillId: '7770b059-1f35-4d1a-a399-707020a0071a',
  experimentIds: {
    strikedSubPrices: 'GSAbkUBMTNW0q8x9hc9csw',
    socialChannels: 'sJwWGRvLSX6o7t-pL9J4Qw',
    trackDetailsTexts: 'zTYlDCdqTI-3ugIM80cUwQ'
  }
};
