export class PathUtils {

  // Para la parte de ? o #
  static firstOccurrence(firstOption: string, secondOption: string, url: string) {
    const firstIndex = url.indexOf(firstOption);
    const secondIndex = url.indexOf(secondOption);
    if (firstIndex === -1 && secondIndex !== -1) {
      return secondOption;
    } else if (firstIndex !== -1 && secondIndex === -1) {
      return firstOption;
    } else if (firstIndex === -1 && secondIndex === -1) {
      return null;
    } else {
      if (firstIndex < secondIndex) {
        return firstOption;
      } else {
        return secondOption;
      }
    }
  }

  static addTrailingSlash(path: string) {
    // chequear si tiene ? o #
    const occurrence = PathUtils.firstOccurrence('?', '#', path);
    if (occurrence) {
      // caso en el q es query string o #
      const splitted = path.split(occurrence, 2);
      const firstElement = splitted.shift();
      const joiner = firstElement.endsWith('/') ? '' : '/';
      const finalPath = firstElement + joiner + occurrence + splitted.join();
      return finalPath;
    } else {
      if (path.endsWith('/')) {
        path = path.slice(0, -1);
      }
      return path + '/';
    }
  }

}