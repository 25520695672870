import { Component, OnInit, OnDestroy } from '@angular/core';

import { InitService } from '../../services/init.service';
import { Subscription } from 'rxjs';
import { Article } from '../../models/article.model';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'hs-recent-articles',
  templateUrl: './recent-articles.component.html',
  styleUrls: ['./recent-articles.component.scss']
})
export class RecentArticlesComponent implements OnInit, OnDestroy {

  recentArticles: Article[];
  subscription: Subscription = null;


  constructor(
    private init: InitService,
    public locale: LocaleService
  ) { }

  ngOnInit(): void {
    this.subscription = this.init.data.subscribe(response => {
      this.recentArticles = response.recentHelpArticles;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
