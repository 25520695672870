import { Injectable, NgZone } from '@angular/core';
import { Subscriptions } from '../models/subscriptions.model';
import { Testimonial } from '../models/init.model';
import { Flags } from '../models/flags.model';
import { User } from '../../auth/models/user.model';
import { LocaleService } from './locale.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Experiment, ExperimentsService } from './experiments.service';
import { UtilsService } from './utils.service';
import { CartItemFrom } from '../models/cart/cart-item.model';

@Injectable({
  providedIn: 'root',
})
export class WebComponentService {
  constructor(
    private ngZone: NgZone,
    private cartService: CartService,
    private locale: LocaleService,
    private auth: AuthService,
    private experiments: ExperimentsService,
    private utils: UtilsService
  ) {}

  public openFreeTrialModal(user: User, flags: Flags, subscriptions: Subscriptions, testimonials: Testimonial[], from: CartItemFrom) {
    if (!this.utils.isBrowser) return;
    window.location.href = this.locale.getLangLink('/subscriptions');

    // const popup = document.createElement('free-trial-modal') as any;
    // popup.setUser(user);
    // popup.set('flags', flags);
    // popup.set('subscriptions', subscriptions);
    // popup.set('testimonials', testimonials);
    // popup.set('fromOverride', from);
    // popup.on('checkout', () => {
    //   this.ngZone.run(() => {
    //     this.cartService.reinitCart();
    //     this.locale.navigate(['/checkout']);
    //     document.body.removeChild(popup);
    //   });
    // });
    // popup.on('close', () => {
    //   document.body.removeChild(popup);
    // });
    // popup.on('login', (event) => {
    //   this.ngZone.run(() => {
    //     this.auth.autoLogin(event.detail);
    //   });
    // });
    // document.body.appendChild(popup);
  }
}
