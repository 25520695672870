<div class="mt-5">
  <a lang class="recent">confirm.recent-articles</a>
  <div class="mt-5">

  </div>

  <div class="mb-4" *ngIf="recentArticles">
    <div class="d-flex flex-column">
      <a *ngFor="let article of recentArticles" class="article"
        [langLink]="['/' + locale.getHelpSlug(), article.categorySlug, article.slug]">
        <img class="article-img" alt="Article" title="Article" src="/assets/receipt-images/article.svg">
        {{article.title}}
      </a>

    </div>

  </div>

</div>
