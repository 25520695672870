export class UserSubscription {
  constructor(
    public isSubscribed: boolean = false,
    public isScale: boolean = false,
    public isAdmin: boolean = false,
    public isEditor: boolean = false,
    public isTrial: boolean = false,
    public name: string = ''
  ) { }
}
