import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Service } from './service';
import { SEO, SEOLangURLs } from '../models/seo.model';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocaleService } from './locale.service';
import { ZarazService } from 'src/app/gtag/gtag.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ErrorsService } from './errors.service';
import { UtilsService } from './utils.service';
import { ServerCacheService } from './server-cache.service';

@Injectable({
  providedIn: 'root',
})
export class SEOService extends Service {

  public langUrls: SEOLangURLs;

  public fallbackLangUrls: SEOLangURLs = {
    en: environment.frontUrl,
    es: environment.frontUrl + 'es/',
    de: environment.frontUrl + 'de/',
    'pt-br': environment.frontUrl + 'pt-br/'
  };

  private responseCache = new Map();
  private previousPath: string = null;

  constructor(
    http: HttpClient,
    @Inject(DOCUMENT) private doc,
    private router: Router,
    public locale: LocaleService,
    private titleService: Title,
    private meta: Meta,
    private gtag: ZarazService,
    private errorsService: ErrorsService,
    private utils: UtilsService,
    private serverCacheService: ServerCacheService
  ) {
    super(http);

    if (this.utils.isBrowser) {
      // Lang URLs are cached for the initial request, as the client doesn't call
      // updateSEO on the initial load.
      this.langUrls = this.serverCacheService.get<SEOLangURLs>("seo-lang-urls", null);
    }
  }

  /**
   * Calls the SEO web service and returns the relevant information for the supplied path.
   */
  get(path: string, lang: string): Observable<SEO> {
    const cache = this.responseCache.get(path);
    if (!cache) {
      const request = this.http.get<SEO>(this.apiUrl + '/v1/seo?path=' + path + '&lang=' + lang);
      this.responseCache.set(path, request.pipe(shareReplay()));
    }
    return this.responseCache.get(path);
  }

  /**
   * This should be called on every page change.
   * It calls the SEO web service, updates the page meta tags and sends a new page view to Google Analytics.
   */
  public updateSEO(url: string) {
    if (!environment.production) {
      console.log(url, this.locale.lang);
    }
    if (this.locale.lang !== 'en' && !url.startsWith('/' + this.locale.lang)) {
      url = '/' + this.locale.lang + url;
    }
    const path = url.split('?')[0];
    if (path !== this.previousPath) {
      this.previousPath = path;
      this.get(path, this.locale.lang).subscribe(
        (seo) => {
          this.langUrls = seo.i18n;
          if (!this.utils.isBrowser) {
            this.serverCacheService.set("seo-lang-urls", this.langUrls);
          }
          this.setSEOHead(seo, url);
          if (seo.canonical) {
            this.setFullCanonical(seo.canonical);
          }
        },
        (error) => {
          if (this.utils.isBrowser) {
            this.errorsService.track(error, ['Client SEO Update']);
          } else {
            this.errorsService.track(error, ['Server SEO Update']);
          }
          this.setSEOHead(null, url);
        }
      );
    }
    this.createCanonical(url);
  }

  /**
   * * Updates the page meta tags and sends a new page view to Google Analytics.
   */
  private setSEOHead(seo: SEO, url: string) {
    let pageTitle = seo?.title;

    if (!pageTitle) {
      pageTitle = 'Royalty Free Music - HookSounds';
    }
    this.titleService.setTitle(pageTitle);
    if (seo?.meta) {
      seo.meta.forEach((meta) => {
        this.meta.updateTag(meta as any);
      });
    }
    if (seo?.i18n) {
      this.setHreflang(seo.i18n);
    }
  }

  createLink(rel: string, href: string, id: string = null) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', rel);
    link.setAttribute('href', href);
    if (id) {
      link.setAttribute('id', id);
    }

    this.addChildToHead(link);
  }

  setHreflang(urls: SEOLangURLs) {
    this.removeOldHreflang();
    const languages = ['en', 'es', 'de', 'pt-br'];
    this.addSingleHreflang('x-default', urls['en']);
    for (const lang of languages) {
      if (urls[lang]) {
        this.addSingleHreflang(lang, urls[lang]);
      }
    }
  }

  private addSingleHreflang(lang: string, href: string) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', lang);
    link.setAttribute('href', href);
    link.setAttribute('id', 'hreflang-' + lang);
    this.addChildToHead(link);
  }

  private addChildToHead(child) {
    // const title = this.doc.head.getElementsByTagName('title');
    // if (title && title.length > 0) {
    //   title[0].after(child);
    // } else {
      this.doc.head.appendChild(child);
    // }
  }

  removeOldHreflang() {
    this.removeElementById('hreflang-x-default');
    this.removeElementById('hreflang-en');
    this.removeElementById('hreflang-es');
    this.removeElementById('hreflang-de');
    this.removeElementById('hreflang-pt-br');
  }

  removeElementById(id: string) {
    this.doc.getElementById(id)?.remove();
  }

  createCanonical(url: string) {
    this.removeElementById('canonical');
    if (url) {
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }

      let urlNoLang = url;
      if (!urlNoLang.startsWith('/')) {
        urlNoLang = '/' + urlNoLang;
      }
      let oldUrl = null;
      do {
        oldUrl = urlNoLang;
        urlNoLang = urlNoLang.replace('/es/', '/');
        urlNoLang = urlNoLang.replace('/de/', '/');
        urlNoLang = urlNoLang.replace('/pt-br/', '/');
      } while (oldUrl !== urlNoLang);

      if (urlNoLang.includes('?')) {
        urlNoLang = urlNoLang.split('?', 1)[0];
      }
      if (!urlNoLang.endsWith('/')) {
        urlNoLang += '/';
      }

      if (this.locale.lang === 'en' || urlNoLang.startsWith('/' + this.locale.lang + '/')) {
        this.createLink('canonical', 'https://www.hooksounds.com' + urlNoLang, 'canonical');
      } else {
        this.createLink('canonical', 'https://www.hooksounds.com/' + this.locale.lang + urlNoLang, 'canonical');
      }
    }
  }

  setFullCanonical(fullUrl: string) {
    this.removeElementById('canonical');
    this.createLink('canonical', fullUrl, 'canonical');
  }


}
