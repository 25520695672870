import { HttpErrorResponse } from '@angular/common/http';
import { User } from "../auth/models/user.model";
import { Injectable } from "@angular/core";
import { ErrorsService } from "../shared/services/errors.service";
import { environment } from "../../environments/environment";

declare var Sentry;

@Injectable()
export class BrowserErrorsService implements ErrorsService {

  public track(e, tags = []) {
    if (e instanceof HttpErrorResponse) {
      this.setContext('HTTP_ERROR', e);
    }
    e = ErrorsService.getError(e);
    if (tags && tags.length > 0) {
      this.setContext('errorTags', { tags });
    }
    if ('Sentry' in window) {
      Sentry.captureException(e?.originalError || e);
    }
    if (!environment.production) {
      console.error(e);
    }
  }

  public trackAndThrow(e) {
    e = ErrorsService.getError(e);
    if ('Sentry' in window) {
      Sentry.captureException(e?.originalError || e);
    }
    throw e;
  }

  public setUser(user: User) {
    try {
      if (user) {
        if ('Sentry' in window) {
          Sentry.configureScope(scope => {
            scope.setUser({
              email: user?.email,
              username: user?.login,
            });
          });
        }
      } else {
        if ('Sentry' in window) {
          Sentry.configureScope(scope => scope.setUser(null));
        }
      }
    } catch (e) {
      this.track(e);
    }
  }

  public setContext(key: string, data: object) {
    if ('Sentry' in window) {
      Sentry.setContext(key, data);
    }
  }


}
