import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { Service } from 'src/app/shared/services/service';
import { Track } from '../../shared/models/tracks-sfx/track.model';
import { RandomTrackService } from './random-track.service';
import { StorageService } from "../../shared/services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class TrackQueueService extends Service {

  /**
   * Emitted when a new track is added/removed from the queue.
   */
  public queue: BehaviorSubject<Array<Track>>;
  private currentIndex: number;

  constructor(
    http: HttpClient,
    errorsService: ErrorsService,
    private storage: StorageService
  ) {
    super(http);
    if (this.storage.has('queue')) {
      try {
        this.queue = new BehaviorSubject<Array<Track>>(this.storage.get('queue'));
      } catch (e) {
        errorsService.track(e, ['TrackQueueService']);
        this.queue = new BehaviorSubject<Array<Track>>([]);
      }
    } else {
      this.queue = new BehaviorSubject<Array<Track>>([]);
    }
    this.currentIndex = 0;
  }

  add(track: Track, seek: number = 0) {
    if (track.isSoundEffect) {
      return;
    }
    if (this.queue.value.length === 0 || track?.id !== this.current?.id) {
      this.queue.value.push(track);
      this.currentIndex = this.queue.value.length - 1;
      this.updateQueue();
    }
  }

  getQueue(): Array<Track> {
    return this.queue.value;
  }

  updateQueue() {
    this.queue.next(this.queue.value);
    // Remove oldest track if the queue has more than 20 tracks.
    if (this.queue.value.length > 20) {
      this.currentIndex--;
      this.queue.value.shift();
    }
    this.storage.set('queue', this.queue.value);
  }

  get current(): Track {
    return this.queue.value[this.currentIndex];
  }

  get hasPrev(): boolean {
    return this.currentIndex > 0;
  }

  get hasNext(): boolean {
    return this.currentIndex < this.queue.value.length - 1;
  }

  moveNext(): Track {
    if (this.hasNext) {
      this.currentIndex++;
      return this.current;
    }
    return null;
  }

  movePrev(): Track {
    if (this.hasPrev) {
      this.currentIndex--;
      return this.current;
    }
    return null;
  }

}
