import { ZarazService } from 'src/app/gtag/gtag.service';
import { AuthService } from '../../../auth/services/auth.service';
import { ModalService } from '../../../shared/services/modal.service';
import { MediaService } from '../../services/media.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { Track } from 'src/app/shared/models/tracks-sfx/track.model';
import { Subscription } from 'rxjs';
import { TracksService } from 'src/app/shared/services/tracks.service';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { WaveService } from 'src/app/wave/wave.service';
import { Options } from '../../../slider/options';
import { UtilsService } from '../../../shared/services/utils.service';
import { Wave } from '../../../wave/wave';
import { Scavenger } from 'src/app/shared/helpers/scavenger.helper';

@Component({
  selector: 'hs-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('waveform') waveContainer: ElementRef;

  @ViewChild('slider', { read: ViewContainerRef })
  private sliderContainer: ViewContainerRef;

  track: Track;
  wavesurfer: Wave;

  subscribed = false;
  downloading = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  minVolume = 0;
  volume = 1;

  collapsed = false;

  volumeOptions: Options = {
    floor: 0,
    ceil: 1,
    step: 0.01,
    translate: (): string => {
      return '';
    }
  };

  loggedIn = false;
  scavenger = new Scavenger(this);

  constructor(
    public mediaService: MediaService,
    public modalService: ModalService,
    public auth: AuthService,
    private trackService: TracksService,
    private errorsService: ErrorsService,
    private waveService: WaveService,
    private gtag: ZarazService,
    private vcref: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.auth.isLoggedInWaitForInit().pipe(this.scavenger.pipe()).subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
    this.auth.subscription.pipe(this.scavenger.pipe()).subscribe(response => {
      this.subscribed = response.isSubscribed;
    });
  }

  ngAfterViewInit() {
    this.mediaService.onUpdate.pipe(this.scavenger.pipe()).subscribe(update => {
      if (this.wavesurfer) {
        try {
          this.wavesurfer.seekTo(update.seek);
        } catch (e) {
          this.errorsService.setContext('Wavesurfer', {
            update,
            track: this.track
          });
          this.errorsService.track(e, ['Wavesurfer']);
        }
      }
    });
    setTimeout( () => {
      this.mediaService.onTrackChange.pipe(this.scavenger.pipe()).subscribe(track => {
        this.waveService.get().then(wavesurfer => {
          this.track = track;
          if (!this.wavesurfer) {
            const options = {
              container: this.waveContainer.nativeElement,
              waveColor: 'white',
              progressColor: '#EA0639',
              barHeight: 1,
              barWidth: 1.1,
              cursorColor: 'none',
              height: '45',
              fillParent: true,
              barGap: '3',
              barRadius: 1,
              normalize: true,
              hideScrollbar: true,
              responsive: 500
            };
            this.wavesurfer = wavesurfer.create(options);

            const element = document.getElementById('waveform');
            element.addEventListener('click', (event) => {
              const rect = element.getBoundingClientRect();
              const x = event.clientX - rect.left;
              let calc = x / element.offsetWidth;
              if (calc < 0) {
                calc = 0;
              }
              if (calc > 1) {
                calc = 1;
              }
              this.mediaService.seekTo(calc);
            }, false);
          }

          if (!this.track.isSoundEffect) {
            try {
              this.wavesurfer.peaks = this.track.waveform;
              // this.wavesurfer.drawer.updateSize();
              this.wavesurfer.drawBuffer();
            } catch (e) {
              this.errorsService.track(e, ['Wavesurfer']);
            }
          }
        });
      });
    }, 100);

    this.loadSlider();
  }

  private async loadSlider() {
    if (this.utils.isBrowser && this.sliderContainer) {
      this.vcref.clear();
      const { SliderComponent } = await import('../../../slider/slider.component');
      const sliderComponent = this.sliderContainer.createComponent(
        this.cfr.resolveComponentFactory(SliderComponent)
      );
      sliderComponent.instance.options = this.volumeOptions;
      sliderComponent.instance.value = this.volume;
      sliderComponent.instance.valueChange.subscribe((value) => {
        this.volume = value;
      });
      sliderComponent.instance.userChange.subscribe((event) => {
        this.setVolume(event);
      });
    }
  }

  next() {
    this.mediaService.next();
  }

  previous() {
    this.mediaService.previous();
  }

  setVolume(event: any) {
    this.volume = event.value;
    this.mediaService.setVolume(this.volume);
  }

  /**
   * Indicates wether the player should be shown or not.
   * This is used to hide the player when playing a SFX.
   * We only show the player for tracks but we still play SFXs through it.
   */
  hidePlayer() {
    return this.track?.isSoundEffect;
  }

  collapsePlayer() {
    this.collapsed = true;
    this.collapsedChange.emit(true);
  }

  expandPlayer() {
    this.collapsed = false;
    this.collapsedChange.emit(false);
  }


  /**
   * Downloads track if user is subscribed
   */
  download() {
    this.downloading = true;
    this.trackService.download(this.track.id).subscribe(response => {
      window.location.href = response.url;
      this.downloading = false;
    });
  }

  ngOnDestroy() {
    this.scavenger.destroy();
    this.wavesurfer?.destroy();
    this.wavesurfer = null;
  }
}
