import { DOCUMENT } from '@angular/common';
import { APP_ID, Injectable, NgModule } from '@angular/core';

/**
 * Create a `StateKey<T>` that can be used to store value of type T with `TransferState`.
 *
 * Example:
 *
 * ```
 * const COUNTER_KEY = makeStateKey<number>('counter');
 * let value = 10;
 *
 * transferState.set(COUNTER_KEY, value);
 * ```
 *
 * @publicApi
 */
export function makeStateKey(key) {
  return key;
}

/**
 * A key value store that is transferred from the application on the server side to the application
 * on the client side.
 *
 * `TransferState` will be available as an injectable token. To use it import
 * `ServerTransferStateModule` on the server and `BrowserTransferStateModule` on the client.
 *
 * The values in the store are serialized/deserialized using JSON.stringify/JSON.parse. So only
 * boolean, number, string, null and non-class objects will be serialized and deserialized in a
 * non-lossy manner.
 *
 * @publicApi
 */
@Injectable()
export class TransferState {

  store = {};
  onSerializeCallbacks = {};

  /** @internal */
  static init(initState) {
    const transferState = new TransferState();
    transferState.store = initState;
    return transferState;
  }

  /**
   * Get the value corresponding to a key. Return `defaultValue` if key is not found.
   */
  get(key, defaultValue) {
    return this.store[key] !== undefined ? this.store[key] : defaultValue;
  }

  /**
   * Set the value corresponding to a key.
   */
  set(key, value) {
    this.store[key] = value;
  }

  /**
   * Remove a key from the store.
   */
  remove(key) {
    delete this.store[key];
  }

  /**
   * Test whether a key exists in the store.
   */
  hasKey(key) {
    return this.store.hasOwnProperty(key);
  }

  /**
   * Register a callback to provide the value for a key when `toJson` is called.
   */
  onSerialize(key, callback) {
    this.onSerializeCallbacks[key] = callback;
  }

  /**
   * Serialize the current state of the store to JSON.
   */
  toJson() {
    // Call the onSerialize callbacks and put those values into the store.
    for (const key in this.onSerializeCallbacks) {
      if (this.onSerializeCallbacks.hasOwnProperty(key)) {
        try {
          this.store[key] = this.onSerializeCallbacks[key]();
        } catch (e) {
          console.warn('Exception in onSerialize callback: ', e);
        }
      }
    }
    return JSON.stringify(this.store);
  }
}


export function initTransferState(doc, appId) {
  // Locate the global variable with the JSON data transferred from the server.
  let initialState = {};
  try {
    if ('initialTransferState' in window) {
      initialState = window['initialTransferState'];
    }
  } catch (e) {
    console.warn('Exception while restoring TransferState for app ' + appId, e);
  }
  if (!initialState) {
    initialState = {};
  }
  return TransferState.init(initialState);
}

/**
 * NgModule to install on the client side while using the `TransferState` to transfer state from
 * server to client.
 *
 * @publicApi
 */
@NgModule({
  providers: [{ provide: TransferState, useFactory: initTransferState, deps: [DOCUMENT, APP_ID] }],
})
export class BrowserTransferStateModule {
}
