import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorsService } from "../shared/services/errors.service";
import { environment } from "../../environments/environment";

@Injectable()
export class ErrorHandlerLog implements ErrorHandler {

  public constructor(private errorsService: ErrorsService) {
  }

  handleError(error: any): void {
    this.errorsService.track(error);
    if (!environment.production) {
      console.log(error);
      // throw error;
    }
  }

}
