import { Directive, Input, ViewContainerRef, ElementRef, Pipe, PipeTransform, OnInit, Renderer2 } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[lang]'
})
export class LangDirective implements OnInit {

  @Input() lang: string;
  @Input() params: any;
  @Input() html = false;

  constructor(
    private locale: LocaleService,
    private host: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (!this.lang) {
      this.lang = this.host.nativeElement.textContent?.trim() || (this.host.nativeElement as any).data?.trim();
    }
    if (this.lang) {
      if (this.html) {
        this.renderer.setProperty(this.host.nativeElement, 'innerHTML', this.locale.translate(this.lang, this.params));
      } else {
        this.renderer.setProperty(this.host.nativeElement, 'innerText', this.locale.translate(this.lang, this.params));
      }
    }
  }

}

@Pipe({
  name: 'lang',
  pure: true
})
export class LangPipe implements PipeTransform {

  constructor(private locale: LocaleService){}

  transform(key: string, params?: any): string | null {
    return this.locale.translate(key, params);
  }
}
