import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class Service {

  private domain = environment.url;
  private path = 'wp-json';

  protected get url(): string {
    if (!this.domain.endsWith('/')) {
      this.domain += '/';
    }
    let langPath = '{{$lang}}';
    if (!this.path.startsWith('/')) {
      langPath += '/';
    }
    // Built URL should always be: https://domain.com/{{$lang}}/wp-json
    return this.domain + langPath + this.path;
  }

  protected get apiUrl(): string {
    if (!this.domain.endsWith('/')) {
      this.domain += '/';
    }
    // Built URL should always be: https://domain.com/api
    return this.domain + 'api';
  }

  protected get urlNoLang(): string {
    if (!this.domain.endsWith('/')) {
      this.domain += '/';
    }
    // Built URL should always be: https://domain.com/wp-json
    return this.domain + this.path;
  }

  constructor(protected http: HttpClient) {
  }

}
