<div class="modal-content">
  <div class="modal-header text-center">
    <h4 class="modal-title" lang>profile.purchases.invoice</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe #frame width="2" height="2" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-black" (click)="print()" lang>actions.print</button>
    <button type="button" class="btn btn-pink" aria-label="Close" (click)="bsModal.hide()" lang>actions.close</button>
  </div>
</div>