import { CookiesService } from 'src/app/cookies/cookies.service';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { InitService } from './init.service';
import { Flags } from '../models/flags.model';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { Service } from './service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export enum Experiment {
  VectorSimilarity = 'vector-similarity',
  PrimaryCategoryPreference = 'primary-category-preference',
}

@Injectable({
  providedIn: 'root',
})
export class ExperimentsService extends Service {
  flags: Flags = new Flags();
  ENDPOINT = '/v2/trackEvent';

  constructor(
    private cookiesService: CookiesService,
    private utilsService: UtilsService,
    private storageService: StorageService,
    private initService: InitService,
    private httpClient: HttpClient
  ) {
    super(httpClient);
    initService.data.subscribe((data) => {
      this.flags = data.flags;
    });
  }

  /**
   * Returns a random integer between 0 (included) and max (excluded).
   * For example, if 5 is provided, the random number will have a value between 0 and 4.
   *
   * @param max The amount of numbers (starting from 0) that will be considered.
   */
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getHeaderName(): string {
    return 'X-Experiments';
  }

  getHeaderValue(): string {
    let participation = this.getAllParticipations();
    let headerValue = '';
    for (const experiment in participation) {
      headerValue += experiment + ':' + participation[experiment] + ',';
    }
    return headerValue.slice(0, -1);
  }

  getSocialChannelsVariant(): number {
    return 0;
  }

  showSocialChannelsBase() {
    return this.getSocialChannelsVariant() === 0;
  }

  showSocialChannelsV1() {
    return this.getSocialChannelsVariant() === 1;
  }

  showSocialChannelsV2() {
    return this.getSocialChannelsVariant() === 2;
  }

  showSocialChannelsV3() {
    return this.getSocialChannelsVariant() === 3;
  }

  /**
   * Returns the version of the experiment the user is in.
   * If the user is not in the experiment, this function includes
   * the user in the experiment, a random version is chosen
   * and stored in local storage.
   */
  getVersion(experiment: Experiment): number {
    if (!this.utilsService.isBrowser) {
      return 0;
    }
    const id = 'expver-' + experiment;
    // Check if the experimentID is already stored in local storage
    const version = this.storageService.getString(id);
    if (version !== null) {
      return parseInt(version, 10);
    } else {
      // If not stored, randomly choose between 0 (base) and 1 (variant)
      const version = Math.round(Math.random());
      // Store the chosen variant in local storage
      this.storageService.setString(id, version.toString());

      this.experimentParticipation(experiment, version);

      return version;
    }
  }

  /**
   * Returns the versions of all experiments the user is in.
   * This function does not include the user in an experiment
   * if they are not already in it.
   */
  getAllParticipations(): Record<string, number> {
    const versions: Record<string, number> = {};
    for (const slug of Object.values(Experiment)) {
      const id = 'expver-' + slug;
      // Check if the experimentID is already stored in local storage
      const version = this.storageService.getString(id);
      if (version !== null) {
        versions[slug] = parseInt(version, 10);
      }
    }
    return versions;
  }

  /**
   * Returns the version of the experiment the user is in,
   * or null if the user is not in the experiment.
   * This function does not include the user in the experiment
   * if they are not already in it.
   */
  getParticipation(experiment: Experiment): number | null {
    if (!this.utilsService.isBrowser) {
      return 0;
    }
    const id = 'expver-' + experiment;
    // Check if the experimentID is already stored in local storage
    const version = this.storageService.getString(id);
    if (version !== null) {
      return parseInt(version, 10);
    } else {
      return null;
    }
  }

  trackEvent(eventData: UserEvent): Observable<any> {
    if (!this.utilsService.isBrowser) return;
    try {
      if (!environment.production) {
        console.log('tracking', eventData);
      }
      this.httpClient
        .post(this.apiUrl + this.ENDPOINT, eventData, {
          headers: {
            [this.getHeaderName()]: this.getHeaderValue(),
          },
        })
        .subscribe(
          (response) => {
            if (!environment.production) {
              console.log('tracking-response', response);
            }
          },
          (error) => {
            if (!environment.production) {
              console.error('Failed to track event:', error);
            }
          }
        );
    } catch (error) {
      if (!environment.production) {
        console.error('Failed to track event:', error);
      }
    }
  }

  trackCartUpdate(cart: any, locale: string = 'en'): void {
    if (!this.utilsService.isBrowser) return;
    this.trackEvent({
      name: 'cart-update',
      visitorId: this.getVisitorId(),
      payload: { cart },
      language: locale,
    });
  }

  trackCartPurchase(cart: any, locale: string = 'en'): void {
    if (!this.utilsService.isBrowser) return;
    this.trackEvent({
      name: 'cart-purchase',
      visitorId: this.getVisitorId(),
      payload: { cart },
      language: locale,
    });
  }

  click(page: string = '', label: string = '', locale: string = 'en') {
    if (!this.utilsService.isBrowser) return;
    this.trackEvent({
      name: 'click',
      visitorId: this.getVisitorId(),
      payload: {
        page: page,
        label: label,
      },
      language: locale,
    });
  }

  experimentParticipation(experiment: string, version: number, locale: string = 'en') {
    if (!this.utilsService.isBrowser) return;
    this.trackEvent({
      name: 'experiment-participation',
      visitorId: this.getVisitorId(),
      payload: {
        experimentSlug: experiment,
        version,
      },
      language: locale,
    });
  }

  getVisitorId(): string {
    if (!this.utilsService.isBrowser) return '';
    let parsed = this.cookieParser(document.cookie);
    let visitorId = parsed['visitorId'];
    if (!visitorId) {
      return '';
    }
    return visitorId;
  }

  private cookieParser(cookieHeader: string | null): Record<string, string> {
    const list: Record<string, string> = {};

    if (!cookieHeader) {
      return list;
    }

    cookieHeader.split(`;`).forEach(function (cookie) {
      try {
        // eslint-disable-next-line prefer-const
        let [name, ...rest] = cookie.split(`=`);
        name = name?.trim();
        if (!name) return;
        const value = rest.join(`=`).trim();
        if (!value) return;
        list[name] = decodeURIComponent(value);
      } catch (e) {
        console.error(`Error parsing cookie: ${cookie}`, e);
      }
    });

    return list;
  }
}

export class UserEvent {
  name: string;
  visitorId: string;
  payload: any;
  language: string;
}
