<div class="ngx-shimmer" [class.light-background]="lightBackground" [class.dark-background]="!lightBackground"
  [ngStyle]="{
    width: width,
    height: shimmerHeight,
    borderRadius: shimmerBorderRadius,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    paddingRight: paddingRight,
    paddingLeft: paddingLeft
  }"></div>
