import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'langHref',
  pure: true,
})
export class LangHrefPipe implements PipeTransform {
  constructor(private locale: LocaleService) {}

  transform(path: string): string | null {
    return this.addLang(path);
  }

  public addLang(link: string): string {
    if (link && typeof link === 'string') {
      if (!link.startsWith('/')) {
        link = '/' + link;
      }
      if (!link.endsWith('/')) {
        link = link + '/';
      }
      if (this.locale.lang !== 'en') {
        link = '/' + this.locale.lang + link;
      }
    }
    return link;
  }
}
