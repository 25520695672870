import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from 'src/app/shared/services/service';
import { HttpClient } from '@angular/common/http';
import { Track } from '../../shared/models/tracks-sfx/track.model';
import { TracksService } from '../../shared/services/tracks.service';
import { Intro } from "../../shared/models/intros/intro.model";

@Injectable({
  providedIn: 'root'
})
export class RandomTrackService extends Service {

  private randomTracks: Track[] = [];

  constructor(
    http: HttpClient,
    private trackService: TracksService
  ) {
    super(http);
  }

  getTrack(): Observable<Track> {
    return new Observable<Track>((observer) => {
      if (this.randomTracks.length === 0) {
        this.trackService.getRandom().subscribe(response => {
          this.randomTracks = response;
          const track = this.randomTracks.shift();
          if (track) {
            observer.next(track);
          }
          observer.complete();
        });
      } else {
        const track = this.randomTracks.shift();
        if (track) {
          observer.next(track);
        }
        observer.complete();
      }
    });
  }

}
