import { WishlistResponse } from '../../models/wishlist-response.model';
import { WishlistService } from '../../services/wishlist.service';
import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Track } from '../../models/tracks-sfx/track.model';
import { Wishlist } from '../../models/wishlist.model';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'hs-wishlist-add',
  templateUrl: './wishlist-add.component.html',
  styleUrls: ['./wishlist-add.component.scss']
})
export class WishlistAddComponent implements OnInit {

  addToNew = false;

  public form: UntypedFormGroup;
  public lists: UntypedFormGroup;

  public wishlists: Wishlist[] = [];
  error = null;

  submittedNew = false;
  submittedExisting = false;

  success = false;
  loading = false;

  wishlistResponse: WishlistResponse;

  trackId: number;
  title: string;


  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private wishListService: WishlistService
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      option: [true],
    });
    this.lists = this.formBuilder.group({
      list: ['', Validators.required],
    });
    this.wishListService.getAll().subscribe(response => {
      this.wishlists = response;
    });

  }

  saveExisting() {
    this.submittedExisting = true;

    if (this.lists.invalid) {
      return;
    }
    this.lists.disable();
    this.loading = true;
    this.wishListService.addToExisting(this.trackId, this.lists.value.list.id).subscribe(response => {
      this.wishlistResponse = response;
      this.success = true;
      this.submittedExisting = false;
      this.loading = false;
      this.lists.enable();
    });
  }


  saveNew() {
    this.submittedNew = true;
    if (this.form.invalid) {
      return;
    }
    this.form.disable();
    this.loading = true;
    this.wishListService.addToNew(this.trackId, this.form.value.title, this.form.value.option).subscribe(response => {
      this.wishlistResponse = response;
      this.success = true;
      this.submittedNew = false;
      this.loading = false;
      this.form.enable();
    });
  }

  selectOption(event: any) {

  }

  selectList(event: any) {
  }

}
