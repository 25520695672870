import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerComponent } from './components/player/player.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [PlayerComponent],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule,
    SharedModule
  ],
  exports: [
    PlayerComponent
  ]
})
export class PlayerModule { }
