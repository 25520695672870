import { CartItem, CartItemFrom } from '../models/cart/cart-item.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { Track } from 'src/app/shared/models/tracks-sfx/track.model';
import { Price } from 'src/app/shared/models/price.model';
import { HttpClient } from '@angular/common/http';
import { Service } from 'src/app/shared/services/service';
import { first, map, take } from 'rxjs/operators';
import { ZarazService } from 'src/app/gtag/gtag.service';
import { MixedModalComponent } from '../components/cart/mixed-modal/mixed-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { InitService } from './init.service';
import { StorageService } from './storage.service';
import { ExperimentsService } from './experiments.service';
import { SubscriptionDetail } from '../models/subscriptions.model';

@Injectable({
  providedIn: 'root',
})
export class CartService extends Service {
  public cart: BehaviorSubject<Array<CartItem>>;
  public errors: Subject<any> = new Subject<any>();

  constructor(
    http: HttpClient,
    private gtag: ZarazService,
    private modalService: BsModalService,
    private init: InitService,
    private storage: StorageService,
    private experimentsService: ExperimentsService,

  ) {
    super(http);

    this.maybeClear();

    if (this.storage.has('cart')) {
      this.reinitCart();
    } else {
      this.cart = new BehaviorSubject<Array<CartItem>>([]);
    }
  }

  public reinitCart() {
    if (this.storage.has('cart')) {
      const parsedCart = this.storage.get('cart');
      if (this.cart) {
        this.cart.next(parsedCart);
      } else {
        this.cart = new BehaviorSubject<Array<CartItem>>(parsedCart);
      }
      this.updateSubscriptionPrices();
    }
  }

  /**
   * Add item to the cart and stores the new cart
   * to keep the cart available in between page reloads.
   */
  add(item: CartItem) {
    this.cart.value.push(item);
    this.cart.next(this.cart.value);
    this.storage.set('cart', this.cart.value);
  }

  setCart(items: CartItem[], fromCart = false) {
    this.clear();
    for (const item of items) {
      this.add(item);
    }
  }

  /**
   * Create a new cart item. Called from track details when a track license is added to the cart.
   */
  addTrack(track: Track, price: Price): Observable<void> {
    return new Observable<void>((subscriber) => {
      if (this.hasSubscription() || this.hasNeverExpiring()) {
        this.openMixedCartTrackModal(track, price, subscriber);
      } else {
        const item: CartItem = {
          name: track.title,
          id: track.id,
          price: price.price,
          regularPrice: price.regularPrice.toString(),
          priceId: +price.id,
          srcset: track.srcset,
          variant: price.name,
          category: Track.GetFirstCategoryName(track),
          isSubscription: false,
        };
        this.add(item);
        this.experimentsService.trackCartUpdate(this.cart.value);
        subscriber.next();
        this.gtag.trackAddToCart(item);
      }
    }).pipe(first());
  }

  /**
   * Create a new cart item. Called from track details when a subscription is added to the cart.
   */
  addSubscription(subscription: SubscriptionDetail, monthly: boolean = null, cartFrom: CartItemFrom = null): Observable<void> {
    if (this.hasSameSubscription(subscription, monthly)) {
      return of<void>(null);
    }
    return new Observable<void>((subscriber) => {
      if (this.hasTrack()) {
        this.openMixedCartSubscriptionModal(subscription, monthly, subscriber);
      } else {
        if (this.hasSubscription() || this.hasNeverExpiring()) {
          this.clear();
        }
        const item: CartItem = {
          name: subscription.name,
          id: subscription.id,
          regularPrice: subscription.originalPrice?.toString(),
          price: subscription.price.toString(),
          shortName: subscription.shortName,
          isSubscription: true,
          isMonthly: monthly,
          from: cartFrom,
        };
        this.add(item);
        this.experimentsService.trackCartUpdate(this.cart.value);
        subscriber.next();
        this.gtag.trackAddToCart(item);
      }
    }).pipe(first());
  }

  /**
   * Remove item from the cart and stores the new cart
   * to keep the cart available in between page reloads.
   */
  remove(item: CartItem) {
    const index = this.cart.value.indexOf(item, 0);
    if (index > -1) {
      // splice returns an array with the deleted item
      // and deletes the item from the original array
      this.cart.value.splice(index, 1);
      this.cart.next(this.cart.value);
    }
    this.storage.set('cart', this.cart.value);
    this.experimentsService.trackCartUpdate(this.cart.value);
  }

  /**
   * List all the cart items.
   */
  getAll(): Array<CartItem> {
    return this.cart.value;
  }

  /**
   * Returns true if there is a sponsorship program in the cart.
   */
  hasSponsorshipProgram(): boolean {
    for (const item of this.cart.value) {
      if (item.shortName?.toLowerCase()?.trim() === 'sponsor') {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if there are subscription items in the cart.
   */
  hasSubscription(): boolean {
    return this.cart.value.find((i) => i.isSubscription) != null;
  }

  hasNeverExpiring(): boolean {
    return this.cart.value.find((i) => i.isNeverExpiring) != null;
  }

  /**
   * Returns true if there are subscription or never expiring items in the cart,
   * which should only be purchased with an associated account.
   */
  requiresLogin(): boolean {
    return this.hasSubscription() || this.hasNeverExpiring();
  }

  /**
   * Returns true if there are annual subscription items in the cart.
   */
  hasAnnualSubscription(): boolean {
    return this.cart.value.find((i) => i.isSubscription && !i.isMonthly) != null;
  }

  hasSameSubscription(subscription: SubscriptionDetail, isMonthly: boolean): boolean {
    return this.cart.value.find((i) => i.id === subscription.id && i.isMonthly === isMonthly) !== undefined;
  }

  /**
   * Returns true if there are subscriptions in the cart.
   */
  hasTrack(): boolean {
    return this.cart.value.find((i) => !i.isSubscription) != null;
  }

  // Updates subscription prices to ensure discounts are applied correctly.
  updateSubscriptionPrices() {
    if (!this.hasSubscription() && !this.hasNeverExpiring()) {
      return;
    }
    this.init.data.pipe(take(1)).subscribe((data) => {
      const subscriptions = [
        data.subscriptions.pro.monthly,
        data.subscriptions.pro.annually,
        data.subscriptions.business.monthly,
        data.subscriptions.business.annually,
      ];
      // Update cart subscription prices
      const modifiedSubscriptions = this.cart.value.map((item) => {
        if (item.isSubscription) {
          const subscription = subscriptions.find((s) => s.id === item.id);
          if (subscription) {
            item.price = subscription.price.toString();
            item.regularPrice = subscription.originalPrice?.toString();
          }
        }
        return item;
      });
      this.setCart(modifiedSubscriptions);
    });
  }

  removeSubscriptions() {
    const nonSubscriptions = this.cart.value.filter((i) => !i.isSubscription);
    this.setCart(nonSubscriptions);
  }

  removeTracks() {
    const nonTracks = this.cart.value.filter((i) => i.isSubscription);
    this.setCart(nonTracks);
  }

  /**
   * Delete all items from cart.
   */
  clear() {
    this.cart.next([]);
    this.storage.remove('cart');
  }

  get totalItems() {
    return this.cart.value.length;
  }

  /**
   * Clears the cart if the "should clear" flag is set.
   */
  maybeClear() {
    if (this.storage.has('clear-cart')) {
      this.storage.remove('clear-cart');
      this.storage.remove('cart');
    }
  }

  openMixedCartTrackModal(track: Track, price: Price, afterAddToCart: Subscriber<void>) {
    const initialState = {
      isTrack: true,
      doAction: false,
    };
    const modal = this.modalService.show(MixedModalComponent, { initialState, class: 'modal-dialog-centered' });
    modal.onHide.subscribe(() => {
      if (modal.content.doAction) {
        this.removeSubscriptions();
        this.addTrack(track, price).subscribe(() => {
          afterAddToCart.next();
        });
      } else {
        afterAddToCart.next();
      }
    });
  }

  openMixedCartSubscriptionModal(subscription: SubscriptionDetail, monthly: boolean, afterAddToCart: Subscriber<void>) {
    const initialState = {
      isTrack: false,
      doAction: false,
    };
    const modal = this.modalService.show(MixedModalComponent, { initialState, class: 'modal-dialog-centered' });
    modal.onHide.subscribe(() => {
      if (modal.content.doAction) {
        this.removeTracks();
        this.addSubscription(subscription, monthly).subscribe(() => {
          afterAddToCart.next();
        });
      } else {
        afterAddToCart.next();
      }
    });
  }
}
