import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { CookiesOptions } from './cookies-options';
import { mergeOptions } from './utils';
import { environment } from 'src/environments/environment';

export const COOKIES_OPTIONS = new InjectionToken('COOKIES_OPTIONS');

@Injectable()
export class CookiesOptionsService {
  private defaultOptions: CookiesOptions;
  private _options: CookiesOptions;

  constructor(@Inject(COOKIES_OPTIONS) options: CookiesOptions = {},
    private injector: Injector) {

    var targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 30);

    this.defaultOptions = {
      path: '/',
      domain: null,
      expires: targetDate,
      secure: environment.production
    };
    this._options = mergeOptions(this.defaultOptions, options);
  }

  get options(): CookiesOptions {
    return this._options;
  }
}
