<div class="grey-background" [hidden]="hidePlayer() || collapsed">

  <div class="container-ld d-flex align-items-center mb-2">


    <a [langLink]="['/royalty-free-music', track?.slug, track?.id]">
      <img [alt]="track?.title" class="track-image" srcset="{{ track?.srcset | srcset:100 }}" />
    </a>
    <a class="track-title truncate-overflow d-none d-sd--webkit-box"
      [langLink]="['/royalty-free-music', track?.slug, track?.id]">
      {{track?.title}}
    </a>

    <div *ngIf="loggedIn" (click)="modalService.openAddWishlist(track.id)">
      <img alt="Wishlist" class="heart" src="/assets/player-images/heart.svg" />
    </div>

    <div class="d-flex align-items-center">
      <div (click)="previous()">
        <img alt="Previous" class="prev-next prev" src="/assets/player-images/previous.svg">
      </div>
      <div class="play-pause">

        <div *ngIf="!mediaService.isLoading() && mediaService.isPaused()" (click)="mediaService.resume()">
          <img alt="Play" class="play-pause-button" src="/assets/player-images/play.svg">
        </div>
        <div *ngIf="!mediaService.isLoading() && mediaService.isPlaying()" (click)="mediaService.pause()">
          <img alt="Pause" class="play-pause-button" src="/assets/player-images/pause.svg">
        </div>
        <div *ngIf="mediaService.isLoading()">
          <hs-spinny-spin size="2.9"></hs-spinny-spin>
        </div>

      </div>
      <div (click)="next()">
        <img alt="Next" class="prev-next next" src="/assets/player-images/next.svg" />
      </div>
    </div>

    <div class="d-none d-t-flex align-items-center flex-grow-1">
      <div class="current-time" *ngIf="mediaService.currentTime">{{mediaService.currentTime * 1000 |
        date:'mm:ss':'UTC'}}</div>
      <div class="current-time" *ngIf="!mediaService.currentTime">00:00</div>
      <div id="waveform" class="waveform" #waveform></div>
      <div class="time" *ngIf="mediaService.duration">{{(mediaService.duration ?? 0.001) * 1000 | date:'mm:ss':'UTC'}}
      </div>
      <div class="time" *ngIf="!mediaService.duration">00:00</div>
    </div>

    <div class="slider-container d-none d-sd-flex">
      <div class="volume-slider">
        <ng-template #slider></ng-template>
      </div>
    </div>


    <button *ngIf="subscribed && !downloading" class="download btn d-none d-d-flex" (click)="download()">
      <img alt="Download" class="download-image" src="/assets/player-images/download.svg" />{{ 'actions.download' |
      lang
      }}
    </button>

    <button *ngIf="!subscribed && !downloading" class="download btn d-none d-d-flex"
      (click)="modalService.openDownloadTrack(track)">
      <img alt="Download" class="download-image" src="/assets/player-images/download.svg" />{{ 'actions.download' |
      lang
      }}
    </button>

    <button *ngIf="downloading" class="download btn d-none d-d-flex">
      {{ 'common.downloading' | lang }}...</button>
    <!-- d-flex: for all screen sizes display: none -->
    <!-- d-t-none: for "T" screen or bigger display: none -->
    <button *ngIf="subscribed && !downloading" class="btn d-flex d-d-none" (click)="download()">
      <img alt="Download" class="download-image" src="/assets/player-images/download.svg">
    </button>
    <button *ngIf="!subscribed && !downloading" class="btn d-flex d-d-none"
      (click)="modalService.openDownloadTrack(track)">
      <img alt="Download" class="download-image" src="/assets/player-images/download.svg">
    </button>
    <button *ngIf="downloading" class="btn d-flex d-d-none" (click)="modalService.openDownloadTrack(track)">
      {{ 'common.downloading' | lang }}...</button>


    <!-- d-none: display: none for all screen sizes -->
    <!-- d-t-flex: for "T" screen or bigger display: flex -->

    <a class="details btn d-none d-d-flex" [langLink]="['/royalty-free-music', track?.slug, track?.id]">
      <img class="details-image" src="/assets/player-images/info.svg" />{{ 'common.details' | lang }}</a>
    <a class="details-mobile btn d-flex d-d-none" [langLink]="['/royalty-free-music', track?.slug, track?.id]">
      <img class="details-image" src="/assets/player-images/info.svg">
    </a>

    <div class="d-none d-t-flex" *ngIf="!collapsed" (click)="collapsePlayer()">
      <img alt="Hide player" class="down-arrow-player arrow clickable" src="/assets/shared/down-pink-arrow.svg">
    </div>

  </div>
</div>

<div class="container-ld d-flex justify-content-end">

  <div class="d-none d-t-flex" *ngIf="collapsed" (click)="expandPlayer()">
    <img alt="Show player" class="up-arrow-player clickable" src="/assets/shared/collapsed-tab.svg">
  </div>
</div>
