<div class="modal-content">
  <div class="modal-header text-center">
    <h4 lang class="modal-title text-center">track-modal.v2.title</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="padding-body">
      <div *ngIf="flags?.seasonal" class="d-flex justify-content-center">
        <img class="discount" [src]='utils.seasonalImages.name'>
      </div>
      <h3
        class="text-center subtitle unlimited-music">{{ 'track-modal.unlimited-music' | lang:{price: (subscriptions?.pro?.annually?.monthlyPrice | price)} }}</h3>
      <a *ngIf="!flags?.freeTrial || !auth.canSeeTrial()"  class="btn get-access" (click)="getFullAccess()">
        {{'subscriptions.get-full-access' | lang }}
      </a>
      <a *ngIf="flags?.freeTrial && auth.canSeeTrial()"  class="btn get-access" (click)="getFullAccessTrial()">
        {{'free-trial.get-started' | lang }}
      </a>
      <!-- <h3 lang class="text-center subtitle quote">track-modal.v2.quote</h3> -->

      <div class="separator or mb-5 mt-6" lang>track-modal.v2.or-single-license</div>


      <hs-licenses-list [prices]="track.prices" [selectedPrice]="selectedPrice" [lightBackground]="true"
                        [showBullets]="true" [strikethroughPink]="false"
                        (selectedPriceChange)="setSelected($event)"></hs-licenses-list>

      <div class='d-flex justify-content-between'>
        <div class='btn add-to-cart d-flex align-items-center justify-content-center'
             (click)='addTrackAndHide(selectedPrice)'>
          <img alt='Add to cart' title='Add to cart' src='/assets/track-details-images/supermarket-plus-white.svg'
               loading='lazy'>
          {{ 'track-details.add-to-cart' | lang }}
        </div>

        <div class='btn buy-now d-flex align-items-center justify-content-center' (click)='buy(selectedPrice)'>
          <img class='trolley' alt='Buy now' title='Buy now' src='/assets/track-details-images/supermarket-white.svg'
               loading='lazy'>
          {{ 'track-details.buy-now' | lang }}
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <p lang class="terms">track-modal.terms</p>
      </div>

    </div>
    <div>

    </div>
    <!-- <a (click)="addSubscriptionToCart()" style="cursor: pointer; font-weight: bold;">Get full access</a> -->

    <!-- <a>{{subscriptions.business.monthly.shortName}} - {{subscriptions.business.monthly.price}}</a> -->
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between grey-background padding-summary-footer">
      <div class="d-flex flex-column ">
        <a class="hundred-percent">100%</a>
        <a lang class="guaranteed">cart.order.guaranteed</a>
        <p lang class="newlines money-back">cart.order.money-back-guarantee</p>
      </div>
      <div class="d-flex flex-column text-min-width">
        <a class="secured-ssl"><img class="secured-ssl-img" alt="Secured with SSL" title="Secured with SSL"
                                    src='/assets/cart-images/secure-ssl.svg'>
          {{'cart.order.SSL' | lang }}</a>
        <a lang class="question">cart.order.question</a>
        <a lang class="phone" href="tel:{{ 'common.contact-phone' | lang }}">common.contact-phone</a>
        <!-- <div lang class="chat" (click)="openChat()">cart.order.chat</div> -->
      </div>

    </div>
  </div>
</div>
