import { SoundEffect } from '../../../models/sound-effect.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Track } from '../../../models/tracks-sfx/track.model';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ModalService } from '../../../services/modal.service';
import { TracksService } from '../../../services/tracks.service';
import { MediaService } from 'src/app/player/services/media.service';
import { PlayerStatus } from 'src/app/player/models/player-status.model';


@Component({
  selector: 'hs-sfx',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sfx.component.html',
  styleUrls: ['./sfx.component.scss']
})
export class SfxComponent implements OnInit, OnDestroy {

  @Input() sfx: Track;
  @Input() slug: string = '';
  @Input() noBorder = false;

  private trackSubscription: Subscription;
  private updateSubscription: Subscription;

  subscribed = false;
  subscriptionUser: Subscription;

  downloading = false;

  current: SoundEffect;

  stopped = true;
  loading = false;

  constructor(
    private mediaService: MediaService,
    public auth: AuthService,
    public modalService: ModalService,
    private trackService: TracksService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.trackSubscription = this.mediaService.onTrackChange.subscribe(track => {
      this.updateSubscription?.unsubscribe();
      if (track?.id == this.sfx.id) {
        this.updateSubscription = this.mediaService.onUpdate.subscribe(update => {
          if (update.status == PlayerStatus.Playing) {
            this.stopped = false;
            this.loading = false;
          } else if (update.status == PlayerStatus.Loading) {
            this.loading = true;
          } else {
            this.loading = false;
            this.stopped = true;
          }
          this.cdr.detectChanges();
        });
      } else if (!this.stopped || this.loading) {
        this.stopped = true;
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
    this.subscriptionUser = this.auth.subscription.subscribe(response => {
      this.subscribed = response.isSubscribed;
    });
  }

  play() {
    this.loading = true;
    this.mediaService.play(this.sfx);
  }

  stop() {
    this.updateSubscription?.unsubscribe();
    this.mediaService.stop();
    this.stopped = true;
    this.loading = false;
  }

  download() {
    if (this.subscribed) {
      this.downloading = true;
      this.trackService.download(this.sfx.id, true).subscribe(response => {
        window.location.href = response.url;
        this.downloading = false;
      });
    } else {
      this.modalService.openSubscription(true);
    }
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
    this.trackSubscription?.unsubscribe();
    this.subscriptionUser?.unsubscribe();
  }

}
