import { LocaleService } from '../../services/locale.service';
import { Genre } from '../genre.model';
import { AdditionalTracks } from './additional-tracks.model';
import { Price } from '../price.model';
import { Tag } from '../tag.model';
import { Mood } from '../mood.model';
import { ImageSrc } from 'src/app/shared/models/image-src.model';
import { Router } from '@angular/router';

export class Track {
  id: number;
  newSlug: string;
  title: string;
  slug: string;
  image: string;
  srcset: ImageSrc[];
  description: string;
  mp3: string;
  duration: string;
  tempo: string;
  versions: string;
  sales: string;
  comments: string;
  filename: string;

  type: string;
  mainSlug: string;

  prices: Array<Price>;
  tags: Array<Tag>;
  genres: Array<Genre>;
  moods: Array<Mood>;
  categories: Array<Tag>; // SFX categories

  isPremium: boolean;
  isNew: boolean;
  isSoundEffect: boolean;
  hidePlayer: boolean;

  waveform: number[];
  additionalTracks: AdditionalTracks;

  shadow: string;

  public static GetSchema(track: Track, router: Router, localeService: LocaleService) {
    const slug = track.isSoundEffect ? 'sound-effects' : 'royalty-free-music';
    let path = router.createUrlTree([slug, track.slug, track.id]).toString();
    if (path.charAt(0) === '/') {
      path = path.substr(1);
    }

    const splitDuration = track.duration.split(':');
    let durationISO = 'T0M0S';
    if (splitDuration.length > 1) {
      durationISO = 'T' + splitDuration[0] + 'M' + splitDuration[1] + 'S';
    }
    return {
      '@context': 'https://schema.org/',
      '@type': 'MusicRecording',
      '@id': localeService.getLangFullLink(path),
      name: track.title,
      producer: {
        '@type': 'Organization',
        name: 'HookSounds',
      },
      duration: durationISO, // ISO FORMAT
      recordingOf: {
        '@type': 'MusicComposition',
        name: track.title,
      },
      acquireLicensePage: localeService.getLangFullLink(path),
      audio: localeService.getLangFullLink(path),
      offers: track.prices?.map((p) => {
        return {
          '@type': 'Offer',
          name: p.name,
          priceCurrency: 'USD',
          price: p.price,
          identifier: p.id,
          availability: 'https://schema.org/InStock',
        };
      }),
    };
  }

  public static GetFirstCategoryName(track: Track): string {
    if (track.genres?.length) {
      return track.genres[0].name;
    } else if (track.moods?.length) {
      return track.moods[0].name;
    }
    return null;
  }

  public static GetBreadcrumb(track: Track, locale: LocaleService) {
    let name = '';
    let url = '';

    if (track.isSoundEffect) {
      name = 'Sound Effects';
      url = 'https://www.hooksounds.com/sound-effects/';
      if (locale.lang === 'es') {
        name = 'Efectos De Sonido';
        url = 'https://www.hooksounds.com/es/sound-effects/';
      } else if (locale.lang === 'de') {
        name = 'Soundeffekte';
        url = 'https://www.hooksounds.com/de/sound-effects/';
      } else if (locale.lang === 'pt-br') {
        name = 'Efeitos Sonoros';
        url = 'https://www.hooksounds.com/pt-br/sound-effects/';
      }
    } else {
      name = 'Royalty Free Music';
      url = 'https://www.hooksounds.com/royalty-free-music/';
      if (locale.lang === 'es') {
        name = 'Música Libre De Regalías';
        url = 'https://www.hooksounds.com/es/royalty-free-music/';
      } else if (locale.lang === 'de') {
        name = 'GEMAfreie Musik';
        url = 'https://www.hooksounds.com/de/royalty-free-music/';
      } else if (locale.lang === 'pt-br') {
        name = 'Musica Livre De Direitos';
        url = 'https://www.hooksounds.com/pt-br/royalty-free-music/';
      }
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name,
          item: url,
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: track.title,
          item: url + track.slug + '/' + track.id + '/',
        },
      ],
    };
  }
}
