import { FeaturedComponent } from './components/featured/featured.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './components/tabs/tabs.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PublicWishlistComponent } from './components/public-wishlist/public-wishlist.component';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { RouterModule } from '@angular/router';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ChatNowComponent } from './components/chat-now/chat-now.component';
import { ControlErrorsDirective } from './validators/forms/control-errors.directive';
import { ControlErrorComponent } from './validators/forms/control-error/control-error.component';
import { ControlErrorContainerDirective } from './validators/forms/control-error-container.directive';
import { FormSubmitDirective } from './validators/forms/form-submit.directive';
import { LangDirective, LangPipe } from './directives/lang.directive';
import { LangLinkDirective } from './directives/lang-link.directive';
import { SrcsetPipe } from './pipes/srcset.pipe';
import { PricePipe } from './pipes/price.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RecentArticlesComponent } from './components/recent-articles/recent-articles.component';
import { BugmeComponent } from './components/bugme/bugme.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { SafePipe } from './pipes/safe.pipe';
import { SpinnySpinComponent } from './components/spinny-spin/spinny-spin.component';
import { WaveModule } from 'src/app/wave/wave.module';
import { NgxSpinnerComponent } from './components/spinner/components/ngx-spinner/ngx-spinner.component';
import { LicensesListComponent } from './components/licenses-list/licenses-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SfxTableComponent } from './components/tracks-sfx/sfx-table/sfx-table.component';
import { TrackGridComponent } from './components/tracks-sfx/track-grid/track-grid.component';
import { TrackComponent } from './components/tracks-sfx/track/track.component';
import { SfxComponent } from './components/tracks-sfx/sfx/sfx.component';
import { ReceiptDetailsComponent } from './components/cart/receipt-details/receipt-details.component';
import { MixedModalComponent } from './components/cart/mixed-modal/mixed-modal.component';
import { IntrosTableComponent } from './components/intros/intros-table/intros-table.component';
import { IntroComponent } from './components/intros/intro/intro.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SliderModule } from '../slider/slider.module';
import { LangLinkActive } from './directives/lang-link-active.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LangHrefPipe } from './pipes/lang-href.pipe';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { NgxJsonLdComponent } from './components/json-ld/json-ld.component';
import { MusicLandingComponent } from './components/music-landing/music-landing.component';
import { BillingPlansBoxesComponent } from './components/billing-plans-boxes/billing-plans-boxes.component';
import { SocialChannelsComponent } from './components/social-channels/social-channels.component';
import { WishlistAddComponent } from './components/wishlist-add/wishlist-add.component';
import { SubscriptionModalComponent } from './components/subscription-modal/subscription-modal.component';
import { SelectSubscriptionComponent } from './components/select-subscription/select-subscription.component';
import { CustomToggleComponent } from './components/custom-toggle/custom-toggle.component';
import { TrackModalV2Component } from './components/track-modal-v2/track-modal-v2.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { UserDropdownComponent } from './components/user-dropdown.component';
@NgModule({
  declarations: [
    TabsComponent,
    ShimmerComponent,
    ToggleComponent,
    ChatNowComponent,
    ControlErrorsDirective,
    ControlErrorComponent,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    LangDirective,
    LangLinkDirective,
    LangLinkActive,
    LangPipe,
    SrcsetPipe,
    PricePipe,
    SafePipe,
    LangHrefPipe,
    SpinnerComponent,
    RecentArticlesComponent,
    ArticleDetailComponent,
    FeaturedComponent,
    BugmeComponent,
    PublicWishlistComponent,
    NotFoundComponent,
    SpinnySpinComponent,
    NgxSpinnerComponent,
    LicensesListComponent,
    SfxTableComponent,
    TrackGridComponent,
    TrackComponent,
    SfxComponent,
    IntroComponent,
    ReceiptDetailsComponent,
    MixedModalComponent,
    WishlistAddComponent,
    SubscriptionModalComponent,
    IntrosTableComponent,
    LoginComponent,
    SignupComponent,
    NgxJsonLdComponent,
    MusicLandingComponent,
    BillingPlansBoxesComponent,
    SocialChannelsComponent,
    SelectSubscriptionComponent,
    CustomToggleComponent,
    TrackModalV2Component,
    SeparatorComponent,
    UserDropdownComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    PopoverModule,
    SliderModule,
    WaveModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    TabsComponent,
    ShimmerComponent,
    ToggleComponent,
    ChatNowComponent,
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    LangDirective,
    LangLinkDirective,
    LangLinkActive,
    LangPipe,
    SrcsetPipe,
    PricePipe,
    SafePipe,
    LangHrefPipe,
    SpinnerComponent,
    RecentArticlesComponent,
    FeaturedComponent,
    BugmeComponent,
    ArticleDetailComponent,
    PublicWishlistComponent,
    NotFoundComponent,
    SpinnySpinComponent,
    LicensesListComponent,
    SfxTableComponent,
    IntrosTableComponent,
    TrackGridComponent,
    TrackComponent,
    SfxComponent,
    IntroComponent,
    ReceiptDetailsComponent,
    MixedModalComponent,
    WishlistAddComponent,
    SubscriptionModalComponent,
    NgxSpinnerComponent,
    LoginComponent,
    SignupComponent,
    NgxJsonLdComponent,
    MusicLandingComponent,
    BillingPlansBoxesComponent,
    SocialChannelsComponent,
    SelectSubscriptionComponent,
    TrackModalV2Component,
    CustomToggleComponent,
    SeparatorComponent,
    UserDropdownComponent,
  ],
})
export class SharedModule {}
