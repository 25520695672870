import { Component, forwardRef, Inject, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hs-mixed-modal',
  templateUrl: './mixed-modal.component.html',
  styleUrls: ['./mixed-modal.component.scss'],
})
export class MixedModalComponent implements OnInit {

  public isTrack: boolean;
  public doAction: boolean;

  constructor(
    public bsModal: BsModalRef,
  ) {

  }

  ngOnInit() {
  }

  removeTracks() {
    if (!this.isTrack) {
      this.doAction = true;
    }
    this.bsModal.hide();
  }

  removeSubscription() {
    if (this.isTrack) {
      this.doAction = true;
    }
    this.bsModal.hide();
  }

}
