import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hs-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements AfterViewInit {

  @ViewChild('frame') frame: ElementRef;
  invoiceHTML: string;

  constructor(public bsModal: BsModalRef) { }

  ngAfterViewInit(): void {
    this.frame.nativeElement.srcdoc = this.invoiceHTML;
    this.frame.nativeElement.sandbox = 'allow-same-origin allow-scripts allow-modals';
  }

  print() {
    this.frame.nativeElement.contentWindow.print();
  }

}
