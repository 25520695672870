import { Inject, Injectable, Optional } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../../shared/services/utils.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { LocaleService } from '../../shared/services/locale.service';

@Injectable({ providedIn: 'root' })
/**
 * AuthGuard prevents unauthenticated users from accessing restricted routes.
 */
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private auth: AuthService,
    private utils: UtilsService,
    private locale: LocaleService,
    @Optional() @Inject(RESPONSE) private serverResponse: any
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.auth.isLoggedInWaitForInit().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          if (this.utils.isBrowser) {
            this.locale.navigate(['/login']);
          } else {
            this.serverResponse.redirect(302, this.locale.getLangFullLink('/login'));
            this.serverResponse.end();
          }
        }
        return isLoggedIn;
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isLoggedInWaitForInit().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.locale.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return isLoggedIn;
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isLoggedInWaitForInit().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.locale.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return isLoggedIn;
      })
    );
  }
}
