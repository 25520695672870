import { Subscriptions, SubscriptionDetail } from '../../models/subscriptions.model';
import { CartService } from '../../services/cart.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InitService } from 'src/app/shared/services/init.service';
import { Subscription } from 'rxjs';
import { Flags } from "../../models/flags.model";
import { LocaleService } from '../../services/locale.service';
import { UtilsService } from '../../services/utils.service';
import { ExperimentsService } from '../../services/experiments.service';
import { CartItemFrom } from '../../models/cart/cart-item.model';


@Component({
  selector: 'hs-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit, OnDestroy {

  closeBtnName: string;
  subscriptions: Subscriptions;

  /**
   * Annually subscriptions are shown by default.
   */
  monthly = false;
  /**
   * Pro subscriptions are shown by default.
   */
  proSelected = true;

  subscription: Subscription = null;
  flags: Flags = null;
  saveAmount: number;

  constructor(
    public bsModalRef: BsModalRef,
    private cart: CartService,
    private init: InitService,
    private locale: LocaleService,
    public utils: UtilsService,
    private experiments: ExperimentsService
  ) {
  }

  ngOnInit() {
    this.subscription = this.init.data.subscribe(response => {
      this.subscriptions = response.subscriptions;
      this.flags = response.flags;
      this.saveAmount = response.saveAmount;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  addSubscriptionToCart() {
    this.bsModalRef.hide();
    this.cart.addSubscription(this.getCurrentSubscription(), this.monthly, CartItemFrom.SubscriptionModal).subscribe(() => {
      this.locale.navigate(['/checkout']);
    });
  }

  getCurrentPrice(): number {
    if (!this.subscriptions) {
      return null;
    }

    if (this.monthly) {
      return this.getCurrentSubscription().price;
    }
    return this.getCurrentSubscription().monthlyPrice;
  }

  private getCurrentSubscription(): SubscriptionDetail {
    if (!this.subscriptions) {
      return null;
    }
    if (this.proSelected) {
      if (this.monthly) {
        return this.subscriptions.pro.monthly;
      } else {
        return this.subscriptions.pro.annually;
      }
    } else {
      if (this.monthly) {
        return this.subscriptions.business.monthly;
      } else {
        return this.subscriptions.business.annually;
      }
    }
  }

  changeSubscription() {
    this.monthly = !this.monthly;
  }

}
