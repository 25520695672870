<div *ngIf="wishlist" class="container-ld">

  <div class="max-width mb-8">
    <h5 class="title">{{wishlist.name}} <p class='description'>{{ wishlist.description }}</p>
    </h5>

    <table class="table table-borderless">
      <thead class="thead">
        <tr>
          <th scope="col" lang>wishlist.track-name</th>
        </tr>
      </thead>
      <tbody *ngIf="wishlist.items">
        <tr>
        <tr *ngFor="let track of wishlist.items" class="asset">
          <th class="" scope="row">
            <hs-sfx [sfx]="track" [slug]="track.mainSlug" [noBorder]="true"></hs-sfx>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="!wishlist && loading" class="container-ld">
  <hs-shimmer width="100%" height="60rem" class="mt-8 mb-8"></hs-shimmer>
</div>

<hs-not-found *ngIf="!wishlist && !loading"></hs-not-found>
