import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { Service } from './service';
import { Init } from '../models/init.model';
import { Gateway, Gateways } from 'src/app/shared/models/cart/gateway.model';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { ErrorsService } from './errors.service';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class InitService extends Service {
  data: ReplaySubject<Init> = new ReplaySubject<Init>(1);

  constructor(
    http: HttpClient,
    private chatService: ChatService,
    private utils: UtilsService,
    private errorsService: ErrorsService
  ) {
    super(http);

    this.http.get<Init>(this.url + '/snack/v1/init').subscribe({
      next: (data) => {
        // Recreate Gateway objects. Objects returned from the server contain
        // only data and don't contain the methods we defined in the model.
        data.gateways = data.gateways.map((g) => Object.assign(new Gateway(), g));
        this.data.next(data);
        this.chatService.setSubscriptionPrices(data.subscriptions);
      },
      error: (error) => {
        this.errorsService.track(error);
        this.utils.disableResponseCache();
      },
    });
  }

  getGateway(id: Gateways): Observable<Gateway> {
    return this.data.pipe(
      map((init) => {
        return init.gateways.find((g) => g.id === id);
      })
    );
  }
}
