import { environment } from 'src/environments/environment';
import { Injectable, InjectionToken, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { Gtag, Product, Action } from './gtag-definitions';
import { Track } from '../shared/models/tracks-sfx/track.model';
import { CartItem } from '../shared/models/cart/cart-item.model';
import { isPlatformBrowser } from '@angular/common';
import { User } from '../auth/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ZarazService {

  constructor(
    @Inject(PLATFORM_ID) protected platformId: object,
  ) {
  }

  get browser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public set(name: string, value: string | Record<string, unknown> | undefined, options: Record<string, unknown> = {}) {
    if (!this.browser) return;
    if ('zaraz' in window) {
      (window as any).zaraz.set(name, value, options);
    }
  }

  public setUser(user: User) {
    if (!this.browser || !user?.id) return;
    this.set('user_id', user.id.toString(), { scope: 'session' });
    const extraData = {
      email: user.email,
      display_name: user.displayName,
      subscription: user.subscription?.name ?? 'none',
    };
    this.set('user_properties', extraData, { scope: 'session' });
    if ('hj' in window) {
      (window as any).hj('identify', user.id, extraData);
    }
  }

  public track(event: TrackingEvent | string, eventProperties: any = {}) {
    if (!this.browser) return;
    if ('zaraz' in window) {
      if (typeof event === 'string') {
        (window as any).zaraz.track(event, eventProperties);
      } else {
        (window as any).zaraz.track(event.name, event.params);
      }
    }
  }

  public ecommerce(eventName: string, eventProperties: any) {
    if (!this.browser) return;
    if ('zaraz' in window) {
      (window as any).zaraz.ecommerce(eventName, eventProperties);
    }
  }

  public click(label: string) {
    if (!this.browser) return;
    this.track('click', {
      event_label: label,
      environment: environment.tag,
    });
  }

  public trackProductViewed(track: Track) {
    if (!this.browser || !track) return;
    const event: EcommerceEvent = {
      product_id: track.id.toString(),
      name: track.title,
      currency: 'usd',
      quantity: 1,
    };
    this.ecommerce('Product Viewed', event);
  }
  
  public trackProductListViewed(tracks: Track[]) {
    if (!this.browser || !tracks?.length) return;
    const event: EcommerceEvent = {
      products: tracks.map((track, index) => ({
        product_id: track.id.toString(),
        name: track.title,
        currency: 'usd',
        quantity: 1,
        position: index + 1,
      })),
    };
    this.ecommerce('Product List Viewed', event);
  }
  
  public trackAddToCart(item: CartItem) {
    if (!this.browser) return;
    const event: EcommerceEvent = {
      product_id: item.id.toString(),
      name: item.name,
      currency: 'usd',
      price: Number(item.price),
      variant: item.variant,
      category: item.category,
      quantity: 1,
    };
    this.ecommerce('Product Added', event);
  }
  
  public trackRemoveFromCart(item: CartItem) {
    if (!this.browser) return;
    const event: EcommerceEvent = {
      product_id: item.id.toString(),
      name: item.name,
      currency: 'usd',
      price: Number(item.price),
      variant: item.variant,
      category: item.category,
      quantity: 1,
    };
    this.ecommerce('Product Removed', event);
  }

  public trackCheckoutStarted() {
    if (!this.browser) return;
    const event: EcommerceEvent = {};
    this.ecommerce('Checkout Started', event);
  }
  
  public trackOrderCompleted(confirm: Action) {
    if (!this.browser) return;
    const event: EcommerceEvent = {
      order_id: confirm.transaction_id,
      checkout_id: confirm.transaction_id,
      total: Number(confirm.value),
      tax: Number(confirm.tax),
      creative: confirm.event_label,
      products:
        confirm.items?.map((item, index) => ({
          product_id: item.id,
          name: item.name,
          currency: 'usd',
          price: Number(item.price),
          variant: item.variant,
          category: item.category,
          quantity: 1,
          position: index + 1,
        })) ?? [],
    };
    this.ecommerce('Order Completed', event);
  }

}

export interface TrackingEvent {
  name: string;
  params?: Record<string, unknown>;
}

export interface EcommerceEvent {
  currency?: string;
  product_id?: string;
  sku?: string;
  category?: string;
  name?: string;
  brand?: string;
  variant?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  position?: number;
  checkout_id?: string;
  order_id?: string;
  affiliation?: string;
  total?: number;
  tax?: number;
  discount?: number;
  step?: number;
  creative?: string;
  products?: {
    product_id: string;
    name: string;
    price?: number;
    quantity?: number;
    sku?: string;
    category?: string;
    brand?: string;
    variant?: string;
    coupon?: string;
    position?: number;
  }[];
}
