import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'hs-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  @Input() class: string = "mb-8 mt-9";

  constructor(
    private meta: Meta,
    @Optional() @Inject(RESPONSE) private response: any
  ) { }

  ngOnInit(): void {
    this.meta.updateTag({
      name: 'prerender-status-code',
      content: '404'
    });
    this.meta.updateTag({
      name: 'snack:not-found',
      content: 'true'
    });
    if (this.response) {
      this.response.status(404);
    }
  }

}
