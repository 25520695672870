export class Gateway {
  public name: string;
  public id: string;
  public sellerId: string;
  public publicKey: string;
  public vendorId: string;
  public publishableKey: string; // Stripe

  public get isPayPal(): boolean {
    return this.id === Gateways.PayPal;
  }

  public get is2Checkout(): boolean {
    return this.id === Gateways.TCheckout;
  }

  public get isBitpay(): boolean {
    return this.id === Gateways.Bitpay;
  }

  public get isPaddle(): boolean {
    return this.id === Gateways.Paddle;
  }

  public get isSnackPay(): boolean {
    return this.id === Gateways.SnackPay;
  }
  public get isStripe(): boolean {
    return this.id === Gateways.Stripe;
  }
}

export enum Gateways {
  PayPal = 'paypalexpress',
  TCheckout = '2checkout_onsite',
  Bitpay = 'bitpay',
  Paddle = 'paddle',
  SnackPay = 'snack-pay',
  Manual = 'manual',
  Stripe = 'stripe',
}
