import { PlayerModule } from './player/player.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams, HTTP_INTERCEPTORS } from '@angular/common/http';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, PathLocationStrategy, ViewportScroller } from '@angular/common';
import { Router, RouterModule, Scroll } from '@angular/router';
import { JwtInterceptor } from './auth/helpers/jwt.interceptor';
import { ErrorInterceptor } from './auth/helpers/error.interceptor';
import { LanguageInterceptor } from './shared/interceptors/language.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LogInterceptor } from './shared/interceptors/log.interceptor';
import { SharedModule } from './shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TransferHttpCacheInterceptor } from './shared/interceptors/http-transfer.interceptor';

import { BrowserCookiesModule } from './cookies/browser/browser-cookies.module';
import { BrowserTransferStateModule } from './shared/platform/browser/transfer-state';
import { filter, map, pairwise } from 'rxjs/operators';
import { UtilsService } from './shared/services/utils.service';
import { PathUtils } from './shared/helpers/path-utils.helper';
import { ErrorsService } from './shared/services/errors.service';
import { BrowserErrorsService } from './errors/errors-service.browser';
import { ErrorHandlerLog } from './errors/error-handler-log';
import { Observable } from 'rxjs';
import { LocaleService } from './shared/services/locale.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

export function initializeTranslations(locale: LocaleService) {
  return (): Observable<any> => {
    return locale.loadTranslations();
  };
}

const originalPrepareExternalUrl = PathLocationStrategy.prototype.prepareExternalUrl;
PathLocationStrategy.prototype.prepareExternalUrl = function (internal) {
  const url = originalPrepareExternalUrl.call(this, internal);
  if (url === '') {
    return url;
  }
  if (url.endsWith('/')) {
    return url;
  }
  return PathUtils.addTrailingSlash(url);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserCookiesModule.forRoot(),
    BrowserAnimationsModule,
    // External libs
    RecaptchaModule,
    RecaptchaFormsModule,
    // Ngx-bootstrap
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    // Internal
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    SharedModule,
    PlayerModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      deps: [LocaleService],
      multi: true,
    },
    { provide: ErrorsService, useClass: BrowserErrorsService },
    { provide: ErrorHandler, useClass: ErrorHandlerLog },
    TransferHttpCacheInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: TransferHttpCacheInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private router: Router, private utils: UtilsService, private viewportScroller: ViewportScroller) {
    if (!utils.isBrowser) {
      return;
    }

    router.events
      .pipe(
        filter((e) => e instanceof Scroll),
        map((e) => e as Scroll),
        pairwise()
      )
      .subscribe((e) => {
        const previousEvent = e[0];
        const event = e[1];
        if (event.position) {
          // backward navigation
          viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (
            previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !==
            event.routerEvent.urlAfterRedirects.split('?')[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
