import { Routes } from '@angular/router';
import { NoAuthGuard } from './auth/helpers/noauth.guard';
import { PublicWishlistComponent } from './shared/components/public-wishlist/public-wishlist.component';
import { AuthGuard } from './auth/helpers/auth.guard';
import { SvelteGuard } from './auth/helpers/svelte.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

export const routes: Routes = [
  /* Landing module */
  { path: 'landing', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  { path: 'es/landing', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  { path: 'de/landing', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  { path: 'pt-br/landing', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },

  { path: 'ref/:referralUser', loadChildren: () => import('./referral/referral.module').then((m) => m.ReferralModule) },
  {
    path: 'es/ref/:referralUser',
    loadChildren: () => import('./referral/referral.module').then((m) => m.ReferralModule),
  },
  {
    path: 'de/ref/:referralUser',
    loadChildren: () => import('./referral/referral.module').then((m) => m.ReferralModule),
  },
  {
    path: 'pt-br/ref/:referralUser',
    loadChildren: () => import('./referral/referral.module').then((m) => m.ReferralModule),
  },

  /* Profile module */
  {
    path: 'my-profile',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'es/my-profile',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'de/my-profile',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'pt-br/my-profile',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },

  /* Public wishlists module */

  { path: 'wishlist/:id', component: PublicWishlistComponent },
  { path: 'es/wishlist/:id', component: PublicWishlistComponent },
  { path: 'de/wishlist/:id', component: PublicWishlistComponent },
  { path: 'pt-br/wishlist/:id', component: PublicWishlistComponent },

  /* Blog module */

  { path: 'blog', loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule) },
  { path: 'es/blog', loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule) },
  { path: 'de/blog', loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule) },
  { path: 'pt-br/blog', loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule) },

  /* Affiliate module */
  {
    path: 'affiliate-profile',
    loadChildren: () => import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
  },
  {
    path: 'es/affiliate-profile',
    loadChildren: () => import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
  },
  {
    path: 'de/affiliate-profile',
    loadChildren: () => import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
  },
  {
    path: 'pt-br/affiliate-profile',
    loadChildren: () => import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
  },

  /* Become module */

  { path: 'affiliates', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'es/affiliates', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'de/affiliates', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'pt-br/affiliates', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },

  { path: 'partnerships', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'es/partnerships', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'de/partnerships', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'pt-br/partnerships', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },

  { path: 'sponsorship-program', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'es/sponsorship-program', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  { path: 'de/sponsorship-program', loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule) },
  {
    path: 'pt-br/sponsorship-program',
    loadChildren: () => import('./become/become.module').then((m) => m.BecomeModule),
  },

  /* Help module */

  { path: 'help', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },
  { path: 'es/ayuda', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },
  { path: 'de/hilfe', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },
  { path: 'pt-br/ajuda', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule) },

  /* Auth pages module.
   * We must provide data with the path of the page so that we can load the correct page.
   * */
  {
    path: 'login',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'login' },
  },
  {
    path: 'es/login',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'login' },
  },
  {
    path: 'de/login',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'login' },
  },
  {
    path: 'pt-br/login',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'login' },
  },

  {
    path: 'join',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'join' },
  },
  {
    path: 'es/join',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'join' },
  },
  {
    path: 'de/join',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'join' },
  },
  {
    path: 'pt-br/join',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'join' },
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'forgot-password' },
  },
  {
    path: 'es/forgot-password',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'forgot-password' },
  },
  {
    path: 'de/forgot-password',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'forgot-password' },
  },
  {
    path: 'pt-br/forgot-password',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'forgot-password' },
  },

  {
    path: 'resetpass',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'resetpass' },
  },
  {
    path: 'es/resetpass',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'resetpass' },
  },
  {
    path: 'de/resetpass',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'resetpass' },
  },
  {
    path: 'pt-br/resetpass',
    loadChildren: () => import('./auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    canActivate: [NoAuthGuard],
    data: { authSection: 'resetpass' },
  },

  /*
   * Free trial module
   */
  {
    path: 'free-trial',
    loadChildren: () => import('./trial/trial.module').then((m) => m.TrialModule),
  },
  {
    path: 'es/free-trial',
    loadChildren: () => import('./trial/trial.module').then((m) => m.TrialModule),
  },
  {
    path: 'de/free-trial',
    loadChildren: () => import('./trial/trial.module').then((m) => m.TrialModule),
  },
  {
    path: 'pt-br/free-trial',
    loadChildren: () => import('./trial/trial.module').then((m) => m.TrialModule),
  },

  /* Cart module */

  { path: 'checkout', canActivate: [SvelteGuard], component: NotFoundComponent },
  { path: 'es/checkout', canActivate: [SvelteGuard], component: NotFoundComponent },
  { path: 'de/checkout', canActivate: [SvelteGuard], component: NotFoundComponent },
  { path: 'pt-br/checkout', canActivate: [SvelteGuard], component: NotFoundComponent },

  { path: 'payments', loadChildren: () => import('./confirm/confirm.module').then((m) => m.ConfirmModule) },
  { path: 'es/payments', loadChildren: () => import('./confirm/confirm.module').then((m) => m.ConfirmModule) },
  { path: 'de/payments', loadChildren: () => import('./confirm/confirm.module').then((m) => m.ConfirmModule) },
  { path: 'pt-br/payments', loadChildren: () => import('./confirm/confirm.module').then((m) => m.ConfirmModule) },

  {
    path: 'shorts',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  /* Sound effects search module */
  {
    path: 'sound-effects',
    children: [
      {
        path: 'category/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'category/:categorySlug/:subCategorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      // SFX Detail
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      // Search home
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'es/sound-effects',
    children: [
      {
        path: 'category/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'category/:categorySlug/:subCategorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'de/sound-effects',
    children: [
      {
        path: 'category/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'category/:categorySlug/:subCategorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'pt-br/sound-effects',
    children: [
      {
        path: 'category/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'category/:categorySlug/:subCategorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },

  /* Royalty free music module. The order of the routes matters here. The last two routes must not be moved. */
  {
    path: 'royalty-free-music',
    children: [
      {
        path: 'genre/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'genre/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'mood/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'mood/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'es/royalty-free-music',
    children: [
      {
        path: 'genre/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'genre/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'mood/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'mood/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'de/royalty-free-music',
    children: [
      {
        path: 'genre/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'genre/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'mood/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'mood/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'pt-br/royalty-free-music',
    children: [
      {
        path: 'genre/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'genre/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'genre' },
      },
      {
        path: 'mood/:categorySlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'mood/:categorySlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
        data: { categoryType: 'mood' },
      },
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },

  /* Intros and Outros module.
   ** The order of the routes matters here.
   * The last two routes must not be moved.
   * */

  {
    path: 'intros',
    children: [
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'es/intros',
    children: [
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'de/intros',
    children: [
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'pt-br/intros',
    children: [
      {
        path: 'tag/:tagSlug',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'tag/:tagSlug/page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: 'page/:page',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: ':slug/:id',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
      {
        path: '',
        canActivate: [SvelteGuard],
        component: NotFoundComponent,
      },
    ],
  },

  /* About module. */

  { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  { path: 'es/about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  { path: 'de/about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  { path: 'pt-br/about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },

  /* Informative module. */

  { path: 'contact', loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule) },
  {
    path: 'es/contact',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'de/contact',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'pt-br/contact',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },

  {
    path: 'nonprofit',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'es/nonprofit',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'de/nonprofit',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'pt-br/nonprofit',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },

  {
    path: 'education',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'es/education',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'de/education',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'pt-br/education',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },

  {
    path: 'in-store-music',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'es/in-store-music',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'de/in-store-music',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },
  {
    path: 'pt-br/in-store-music',
    loadChildren: () => import('./informative/informative.module').then((m) => m.InformativeModule),
  },

  {
    path: 'subscriptions',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'es/subscriptions',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de/subscriptions',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br/subscriptions',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  { path: 'premium', canActivate: [SvelteGuard], component: NotFoundComponent },
  {
    path: 'es/premium',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de/premium',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br/premium',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  { path: 'business', canActivate: [SvelteGuard], component: NotFoundComponent },
  {
    path: 'es/business',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de/business',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br/business',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  { path: 'explore', loadChildren: () => import('./explore/explore.module').then((m) => m.ExploreModule) },
  { path: 'es/explore', loadChildren: () => import('./explore/explore.module').then((m) => m.ExploreModule) },
  { path: 'de/explore', loadChildren: () => import('./explore/explore.module').then((m) => m.ExploreModule) },
  { path: 'pt-br/explore', loadChildren: () => import('./explore/explore.module').then((m) => m.ExploreModule) },

  {
    path: 'collections',
    loadChildren: () => import('./collections/collections.module').then((m) => m.CollectionsModule),
  },
  {
    path: 'es/collections',
    loadChildren: () => import('./collections/collections.module').then((m) => m.CollectionsModule),
  },
  {
    path: 'de/collections',
    loadChildren: () => import('./collections/collections.module').then((m) => m.CollectionsModule),
  },
  {
    path: 'pt-br/collections',
    loadChildren: () => import('./collections/collections.module').then((m) => m.CollectionsModule),
  },

  /**
   * Assistant studio routes. Added here to navigate to the assistant (Svelte) from Angular.
   */
  {
    path: 'studio',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'es/studio',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de/studio',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br/studio',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  {
    path: 'shortcap',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'es/shortcap',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de/shortcap',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br/shortcap',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  {
    path: '',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'es',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'de',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },
  {
    path: 'pt-br',
    canActivate: [SvelteGuard],
    component: NotFoundComponent,
  },

  /* Landings module. */
  { path: ':landingSlug', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  { path: 'es/:landingSlug', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  { path: 'de/:landingSlug', loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule) },
  {
    path: 'pt-br/:landingSlug',
    loadChildren: () => import('./landings/landings.module').then((m) => m.LandingsModule),
  },

  { path: '**', component: NotFoundComponent },
];
