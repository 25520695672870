import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocaleService } from '../services/locale.service';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable()
/**
 * LanguageInterceptor intercepts http requests from the application
 * to add the current language to the request URL.
 */
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private locale: LocaleService, private utils: UtilsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let activeLang = this.locale.lang;
    if (activeLang === 'en') {
      activeLang = '/';
    } else {
      activeLang = '/' + activeLang + '/';
    }
    let url = request.url.replace('/{{$lang}}/', activeLang.toLowerCase());
    if (!this.utils.isBrowser && environment.localUrl) {
      url = url.replace(environment.url, environment.localUrl);
    }
    const dupReq = request.clone({ url });
    return next.handle(dupReq);
  }
}
