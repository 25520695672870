import { LocationStrategy } from '@angular/common';
import {
  Directive,
  Input,
  ViewContainerRef,
  ElementRef,
  Pipe,
  PipeTransform,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { RouterLinkWithHref, Router, ActivatedRoute, Params } from '@angular/router';
import { LocaleService } from '../services/locale.service';
import { Subject } from "rxjs";

@Directive({
  selector: 'a[langLink]'
})
export class LangLinkDirective extends RouterLinkWithHref implements OnChanges {

  linkValue: string | Array<any>;

  get link(): string | Array<any> {
    return this.linkValue;
  }

  @Input('langLink') set link(value: string | Array<any>) {
    this.linkValue = this.buildLink(value);
    this.routerLink = this.linkValue;
    this.href = this.locationStrat.prepareExternalUrl(this.childRouter.serializeUrl(this.urlTree));
  }

  onChanged = new Subject<RouterLinkWithHref>();

  constructor(
    private childRouter: Router,
    route: ActivatedRoute,
    private locationStrat: LocationStrategy,
    private locale: LocaleService,
  ) {
    super(childRouter, route, locationStrat);
  }

  ngOnChanges(changes: SimpleChanges): any {
    super.ngOnChanges(changes);
    this.onChanged.next(this);
  }

  buildLink(link: string | Array<any>) {
    if (Array.isArray(link)) {
      link[0] = this.addLang(link[0]);
    } else {
      link = this.addLang(link);
    }
    return link;
  }

  private addLang(link: string): string {
    if (link && typeof link === 'string') {
      if (!link.startsWith('/')) {
        link = '/' + link;
      }
      if (!link.endsWith('/')) {
        link = link + '/';
      }
      if (this.locale.lang !== 'en') {
        link = '/' + this.locale.lang + link;
      }
    }
    return link;
  }

}
