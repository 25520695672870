import { Subscription, SubscriptionDetail } from './../../models/subscriptions.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'hs-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() optionLeft: string;
  @Input() optionRight: string;
  @Input() large = false;
  @Input() medium = false;

  @Input() optionSelected: boolean;
  @Output() optionSelectedChange = new EventEmitter<boolean>();

  @Input() lightBackground = false;
  @Input() selectedPink = true;

  @Input() id: string;

  @Output() onChangeToggle = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  setSelected(option: any) {
    this.optionSelected = option.currentTarget.checked;
    this.optionSelectedChange.emit(option.currentTarget.checked);
    // Emit true when toggle is changed so that the animation is shown.
    this.onChangeToggle.emit(true);

  }



}
