<ng-container *ngIf="!success">

    <div class="modal-content">
        <div class="modal-header text-center">
            <h4 lang class="modal-title">wishlist.title</h4>

            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <div class="padding-body relative">

                <div class="d-flex justify-content-center mb-4">
                    <a class="existing option" [class.option-checked]="!addToNew" (click)="addToNew = false">
                        <img class="check"
                            src="{{addToNew? 'assets/shared/unchecked.svg' : 'assets/shared/checked.svg'}}">
                        {{'wishlist.existing' | lang}}
                    </a>
                    <a class="option" (click)="addToNew = true" [class.option-checked]="addToNew">
                        <img class="check"
                            src="{{addToNew? 'assets/shared/checked.svg' : 'assets/shared/unchecked.svg'}}">
                        {{'wishlist.new' | lang}}
                    </a>
                </div>

                <form *ngIf="!addToNew" [formGroup]="lists" (submit)="saveExisting()">
                    <div *ngIf="wishlists" class="form-group mt-auto">
                        <select formControlName="list" class="custom-select form-input select-menu"
                            [ngClass]="{ 'is-invalid': submittedExisting && lists.controls.list.errors }">
                            <option lang value="" selected disabled>wishlist.select</option>

                            <option *ngFor="let list of wishlists" [ngValue]="list">{{list.name}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <button lang class="btn save">actions.save</button>
                    </div> <!-- form-group// -->

                </form>

                <form *ngIf="addToNew" [formGroup]="form" (submit)="saveNew()">
                    <div class="form-group input-group">
                        <input name="title" formControlName="title" class="form-control form-input"
                            placeholder="{{ 'wishlist.wishlist-title' | lang}}" type="text"
                            [ngClass]="{ 'is-invalid': submittedNew && form.controls.title.errors }">
                    </div>

                    <div class="form-group mt-auto">
                        <select formControlName="option" class="custom-select form-input select-menu">
                            <option value="true" selected>Private - Only viewable by you</option>
                            <option value="false">Public - Viewable</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <button lang class="btn save">actions.save</button>
                    </div> <!-- form-group// -->


                </form>

                <hs-spinner [show]="loading"></hs-spinner>


            </div>
        </div>
    </div>


</ng-container>
<ng-container *ngIf="success">
    <div class="modal-content">

        <div class="modal-header text-center">
            <h4 lang class="modal-title">wishlist.success</h4>

            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <div class="padding-body">
                <div class="d-flex flex-column align-items-center">
                    <div class="d-flex justify-content-center mb-4">
                        <img alt="Success" src='/assets/shared/ok.svg'>

                    </div>
                    <div class="success">
                        {{'wishlist.successfully' | lang}} <a (click)="bsModalRef.hide()"
                            [langLink]="['/my-profile/wishlist-details', wishlistResponse.id]">{{wishlistResponse.name}}</a>
                    </div>
                </div>

                <button lang class="btn save" (click)="bsModalRef.hide()">wishlist.done</button>
            </div>

        </div>
    </div>
</ng-container>
