<div class="modal-content">
  <div class="modal-header text-center">
    <h4 lang class="modal-title">track-modal.subscriptions-sub-title</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-center save">
      {{ 'track-modal.save' | lang:{ amount: saveAmount } }}
    </div>

    <div class="padding-body">
      <hs-toggle class="d-flex justify-content-center" [optionRight]="'subscriptions.annually' | lang: { amount: saveAmount }"
        [optionLeft]="'subscriptions.monthly' | lang" [id]="'modal'" [lightBackground]=true [optionSelected]="true"
        (optionSelectedChange)="changeSubscription()">
      </hs-toggle>
      <div *ngIf="flags?.seasonal" class="d-flex justify-content-center">
        <img class="discount" [src]='utils.seasonalImages.name'>
      </div>
      <!-- Show monthly subscriptions as selected in the toggle above -->

      <div *ngIf="monthly" class="subs">
        <div class="d-flex justify-content-between">
          <div class="subs-box">
            <hs-select-subscription [type]="'Pro'" [monthly]="true" [selected]="proSelected"
              [subscriptions]="subscriptions" (click)="proSelected = true" [lightBackground]="true" [modal]="true">
            </hs-select-subscription>
          </div>
          <div class="subs-box">
            <hs-select-subscription [type]="'Business'" [monthly]="true" [selected]="!proSelected"
              [subscriptions]="subscriptions" (click)="proSelected = false " [lightBackground]="true" [modal]="true">
            </hs-select-subscription>
          </div>
        </div>
      </div>

      <!-- Show annualy subscriptions as selected in the toggle above -->

      <div *ngIf="!monthly" class="subs">
        <div class="d-flex justify-content-between">
          <div class="subs-box">
            <hs-select-subscription [type]="'Pro'" [monthly]="false" [selected]="proSelected"
              [subscriptions]="subscriptions" (click)="proSelected = true" [lightBackground]="true" [modal]="true">
            </hs-select-subscription>
          </div>
          <div class="subs-box">
            <hs-select-subscription [type]="'Business'" [monthly]="false" [selected]="!proSelected"
              [subscriptions]="subscriptions" (click)="proSelected = false" [lightBackground]="true" [modal]="true">
            </hs-select-subscription>
          </div>
        </div>
      </div>

      <p class="text-center terms mb-4">{{ 'track-modal.unlimited-music' | lang:{ price: (getCurrentPrice() | price) } }}</p>


      <button lang class="btn btn-pink-big" (click)="addSubscriptionToCart()">
        subscriptions.get-full-access
      </button>

      <div class="d-flex justify-content-center">
        <p lang class="terms">track-modal.terms</p>
      </div>

    </div>
    <div>

    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between grey-background padding-summary-footer">
      <div class="d-flex flex-column">
        <a class="hundred-percent">100%</a>
        <a lang class="guaranteed">cart.order.guaranteed</a>
        <p lang class="newlines money-back">cart.order.money-back-guarantee</p>
      </div>
      <div class="d-flex flex-column text-min-width">

        <a class="secured-ssl"><img class="secured-ssl-img" alt="Secured with SSL" title="Secured with SSL" src='/assets/cart-images/secure-ssl.svg'>
          {{'cart.order.SSL' | lang}}</a>
        <a lang class="question">cart.order.question</a>
        <a lang class="phone" href="tel:{{'common.contact-phone' | lang}}">common.contact-phone</a>
        <a lang class="chat">cart.order.chat</a>

      </div>

    </div>
  </div>
</div>
