<div class="sfx d-flex align-items-center" [class.no-border]="noBorder">
  <div *ngIf="!loading && !stopped" (click)="stop()">
    <img class="play" alt="Stop" title="Stop" src='/assets/home-images/sfx-stop.svg' loading="lazy" width='38' height='38'/>
  </div>
  <div *ngIf="!loading && stopped" (click)="play()">
    <img class="play" alt="Play" title="Play" src='/assets/home-images/sfx-play.svg' loading="lazy" width='42' height='42'/>
  </div>
  <div *ngIf="loading" class="spinner">
    <hs-spinny-spin size="2.63" black="true"></hs-spinny-spin>
  </div>
  <div class="d-flex flex-grow-1 flex-column title-column">
    <a class="title link" [langLink]="[slug ? slug : '/sound-effects', sfx.slug, sfx.id]">{{ sfx.title }}</a>
    <div class="duration">
      {{ sfx.duration }}
    </div>
  </div>
  <div class="download" (click)="download()"></div>
  <a [langLink]="[slug ? slug : '/sound-effects', sfx.slug, sfx.id]">
    <div class="info"></div>
  </a>
</div>
