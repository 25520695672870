import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CookiesService } from 'src/app/cookies/cookies.service';

@Injectable()
/**
 * JwtInterceptor intercepts HTTP requests from the application to add a JWT auth
 * token to the Authorization header if the user is logged in.
 */
export class JwtInterceptor implements HttpInterceptor {

  constructor(private utils: UtilsService, private cookiesService: CookiesService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.utils.isBrowser) {
      request = request.clone({
        withCredentials: true
      });
    } else {
      let token = this.cookiesService.get('hsauth');
      if (token) {
        request = request.clone({
          setHeaders: {
            Cookie: `hsauth=${token}`
          }
        });
      }
    }
    return next.handle(request);

  }
}
