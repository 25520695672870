<div *ngFor="let price of prices" (click)="setSelected(price)" class="d-flex bd-highlight btn price-type"
  [class.light-background]="lightBackground" [class.price-type-light-background]="lightBackground"
  [class.selected-price]="selectedPrice == price && !lightBackground"
  [class.selected-price-light-background]="selectedPrice == price && lightBackground">
  <div *ngIf="showBullets" class="d-flex align-items-center">
    <img alt="Selection indicator" loading="lazy" class="bullet" src="{{selectedPrice == price && lightBackground? 'assets/shared/bullet-selected.svg' : 'assets/shared/bullet.svg'}}">
  </div>

  <div class="p-2 bd-highlight">
    {{price.name}}
  </div>
  <div class="p-2 bd-highlight">
    <button *ngIf="+price.id === 1" type="button" class="btn btn-secondary"
      tooltip="{{ 'tooltip-licenses.use-and-mention' | lang }}" placement="top" container="body">
      <img class="info" alt="Information" loading="lazy" src="{{lightBackground? 'assets/home-images/info-grey.svg': 'assets/home-images/info.svg'}}" />
    </button>
    <button *ngIf="+price.id === 2" type="button" class="btn btn-secondary"
      tooltip="{{ 'tooltip-licenses.pro' | lang }}" placement="top" container="body">
      <img class="info" alt="Information" loading="lazy" src="{{lightBackground? 'assets/home-images/info-grey.svg': 'assets/home-images/info.svg'}}" />
    </button>
    <button *ngIf="+price.id === 3" type="button" class="btn btn-secondary"
      tooltip="{{ 'tooltip-licenses.business' | lang }}" placement="top" container="body">
      <img class="info" alt="Information" loading="lazy"  src="{{lightBackground? 'assets/home-images/info-grey.svg': 'assets/home-images/info.svg'}}" />
    </button>
    <button *ngIf="+price.id === 4" type="button" class="btn btn-secondary"
      tooltip="{{ 'tooltip-licenses.broadcast' | lang }}" placement="top" container="body">
      <img class="info" alt="Information" loading="lazy"  src="{{lightBackground? 'assets/home-images/info-grey.svg': 'assets/home-images/info.svg'}}" />
    </button>

  </div>
  <div class="ml-auto p-2 bd-highlight">
    <div *ngIf="price.regularPrice.toString() != price.price" class="price d-inline" [class.strikethroughPink]="strikethroughPink" [class.strikethroughGrey]="!strikethroughPink"><s>
        {{price.regularPrice | price}} </s></div>
    <div *ngIf="price.regularPrice.toString()!=''" class="price d-inline">{{price.price | price}}</div>
  </div>
</div>
