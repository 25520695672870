import { Injectable, NgZone } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { UtilsService } from './utils.service';
import { Subscriptions } from "../models/subscriptions.model";

declare var $crisp;

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  shouldTrigger = false;
  loaded = false;
  loading = false;

  subscription: Subscription;

  constructor(
    private utils: UtilsService,
    private ngZone: NgZone,
  ) {
    if (utils.isBrowser) {
      $crisp.push(['on', 'session:loaded', () => {
        this.sessionLoaded();
        this.hideLoadingIndicator();
      }]);

      $crisp.push(['on', 'chat:closed', this.hideChatButton]);
    }
  }

  sessionLoaded() {
    this.loaded = true;
    if (this.shouldTrigger) {
      this.runTrigger();
    }
  }

  openChat() {
    if (this.utils.isBrowser) {
      $crisp.push(['do', 'chat:show']);
      $crisp.push(['do', 'chat:open']);
      if (!document.getElementById('crisp-script')) {
        this.loading = true;
        const script = document.createElement('script');
        script.src = 'https://client.crisp.chat/l.js';
        script.async = true;
        script.id = 'crisp-script';
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }
  }

  hideChatButton() {
    if (this.utils.isBrowser) {
      this.ngZone.run(_ => {
        $crisp.push(['do', 'chat:hide']);
      });
    }
  }

  hideLoadingIndicator() {
    if (this.utils.isBrowser) {
      this.ngZone.run(_ => {
        this.loading = false;
      });
    }
  }

  runTrigger() {
    if (this.utils.isBrowser) {
      if (window.innerWidth > 900) {
        this.shouldTrigger = true;
        const countdown = timer(1000);
        this.subscription = countdown.subscribe(x => {
          if (this.shouldTrigger) {
            this.openChat();
          }
        });
      }
    }
  }

  setUserEmail(email: string) {
    if (this.utils.isBrowser) {
      $crisp.push(['set', 'user:email', email]);
      $crisp.push(['do', 'chat:hide']);
    }
  }

  setSubscriptionPrices(subscriptions: Subscriptions) {
    if (this.utils.isBrowser) {
      $crisp.push(["set", "session:data", [
        [
          ["business-monthly", subscriptions.business.monthly.price],
          ["business-yearly", subscriptions.business.annually.monthlyPrice],
          ["pro-monthly", subscriptions.pro.monthly.price],
          ["pro-yearly", subscriptions.pro.annually.monthlyPrice],
        ]
      ]]);
    }
  }

  cancelTrigger() {
    this.shouldTrigger = false;
    this.subscription?.unsubscribe();
  }

}
