<hs-shimmer *ngIf="!subscriptions" width="100%" height="7rem" borderRadius="0.75rem"
    [lightBackground]="lightBackground"></hs-shimmer>

<div *ngIf="subscriptions" class="d-flex justify-content-between align-items-center box" [class.selector]="!modal"
    [class.selector-modal]="modal" (click)="setSelectedSubscription()">

    <div *ngIf="type=='Business'" class="subscription-info">
        <div>
            <div *ngIf="monthly" class="pro-business d-inline">
                {{subscriptions.business.monthly.shortName }}
            </div>
            <div *ngIf="!monthly" class="pro-business d-inline">
                {{subscriptions.business.annually.shortName}}
            </div>
            <img class="info d-inline" alt="Information" title="Information" src='/assets/home-images/info.svg' />
        </div>
        <div *ngIf="monthly" class="price">
            {{subscriptions.business.monthly.price | price}}{{'subscriptions.mo' | lang}}
        </div>
        <div *ngIf="!monthly" class="price">
            {{subscriptions.business.annually.monthlyPrice | price}}{{'subscriptions.mo' | lang}}
        </div>
        <div *ngIf="!monthly" class="billed">
            {{ 'subscriptions.billed-yearly' | lang:{ amount: (subscriptions.business.annually.price | price) } }}
        </div>
        <div lang *ngIf="monthly" class="billed">subscriptions.billed-monthly</div>
    </div>

    <div *ngIf="type=='Pro'" class="subscription-info">
        <div>
            <div *ngIf="monthly" class="pro-business d-inline">
                {{subscriptions.pro.monthly.shortName}}
            </div>
            <div *ngIf="!monthly" class="pro-business d-inline">
                {{subscriptions.pro.annually.shortName}}
            </div>
            <img class="info d-inline" alt="Information" title="Information" src='/assets/home-images/info.svg' />
        </div>
        <div *ngIf="monthly" class="price">
            {{subscriptions.pro.monthly.price | price}}{{'subscriptions.mo' | lang}}
        </div>
        <div *ngIf="!monthly" class="price">
            {{subscriptions.pro.annually.monthlyPrice | price}}{{'subscriptions.mo' | lang}}
        </div>
        <div *ngIf="!monthly" class="billed">
            {{ 'subscriptions.billed-yearly' | lang:{ amount: (subscriptions.pro.annually.price | price) } }}
        </div>
        <div lang *ngIf="monthly" class="billed">
            subscriptions.billed-monthly
        </div>

    </div>

    <div>
        <img *ngIf="selected" class="check check-mobile" alt="Checked" title="Checked" src='/assets/track-details-images/checked.svg'>
        <img *ngIf="!selected" class="check check-mobile" alt="Unchecked" title="Unchecked" src='/assets/track-details-images/unchecked.svg'>
    </div>

</div>
