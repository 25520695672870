import { UtilsService } from 'src/app/shared/services/utils.service';
import { ZarazService } from 'src/app/gtag/gtag.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { CartService } from 'src/app/shared/services/cart.service';
import { SubscriptionDetail, Subscriptions } from 'src/app/shared/models/subscriptions.model';
import { ChatService } from 'src/app/shared/services/chat.service';
import { InitService } from 'src/app/shared/services/init.service';
import { Price } from '../../models/price.model';
import { Track } from '../../models/tracks-sfx/track.model';
import { Flags } from "../../models/flags.model";
import {ExperimentsService} from "../../services/experiments.service";
import { AuthService } from 'src/app/auth/services/auth.service';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { CartItemFrom } from '../../models/cart/cart-item.model';

@Component({
  selector: 'hs-track-modal-v2',
  templateUrl: './track-modal-v2.component.html',
  styleUrls: ['./track-modal-v2.component.scss'],
})
export class TrackModalV2Component implements OnInit, OnDestroy {
  track: Track;
  selectedPrice: Price;

  subscription: Subscription = null;
  subscriptions: Subscriptions;
  flags: Flags = null;
  /**
   * Annually subscriptions are shown by default.
   */
  monthly = false;
  /**
   * Pro subscriptions are shown by default.
   */
  proSelected = true;

  constructor(
    public bsModalRef: BsModalRef,
    private cart: CartService,
    private init: InitService,
    private locale: LocaleService,
    public auth: AuthService,
    private chat: ChatService,
    public gtag: ZarazService,
    public utils: UtilsService,
    public experiments: ExperimentsService,
  ) { }

  ngOnInit() {
    this.subscription = this.init.data.subscribe((response) => {
      this.subscriptions = response.subscriptions;
      this.flags = response.flags;
    });
    this.selectedPrice = this.track.prices.find((price) => price.default);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  setSelected(price: Price) {
    this.selectedPrice = price;
  }

  addSubscriptionToCart() {
    this.bsModalRef.hide();
    this.cart.addSubscription(this.getCurrentSubscription(), this.monthly, CartItemFrom.LicenseModal).subscribe(() => {
      this.locale.navigate(['/checkout']);
    });
  }

  getFullAccess() {
    this.bsModalRef.hide();
    this.locale.navigate(['/premium']);
  }

  getFullAccessTrial() {
    this.bsModalRef.hide();
    this.locale.navigate(['/free-trial']);
  }

  private getCurrentSubscription(): SubscriptionDetail {
    if (this.proSelected) {
      if (this.monthly) {
        return this.subscriptions.pro.monthly;
      } else {
        return this.subscriptions.pro.annually;
      }
    } else {
      if (this.monthly) {
        return this.subscriptions.business.monthly;
      } else {
        return this.subscriptions.business.annually;
      }
    }
  }

  buy(price: Price) {
    this.bsModalRef.hide();
    this.addTrackToCart(price).subscribe(() => {
      this.locale.navigate(['/checkout']);
    });
  }

  addTrackToCart(price: Price): Observable<void>  {
    // this.bsModalRef.hide();
    return this.cart.addTrack(this.track, price);
  }

  addTrackAndHide(price: Price) {
    this.cart.addTrack(this.track, price).subscribe(response => {
      this.bsModalRef.hide();
    });
  }
}
