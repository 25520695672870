import { Track } from '../../shared/models/tracks-sfx/track.model';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MediaUpdate } from '../models/media-update.model';
import { PlayerStatus } from '../models/player-status.model';
import { ErrorsService } from 'src/app/shared/services/errors.service';
import { RandomTrackService } from './random-track.service';
import { TrackQueueService } from './track-queue.service';
import { UtilsService } from "../../shared/services/utils.service";

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private importCache: any = null;
  private unlockedAudio = false;
  private howl?: any = null;
  private volume: number = 1;

  public onTrackChange: ReplaySubject<Track> = new ReplaySubject(1);
  public onUpdate: ReplaySubject<MediaUpdate> = new ReplaySubject<MediaUpdate>(1);

  public track: Track;
  private hasError = false;
  private manualLoading = false;
  public duration = 0;
  public currentTime = 0;
  public currentSeek = 0;

  constructor(
    private errorsService: ErrorsService,
    private queueService: TrackQueueService,
    private randomService: RandomTrackService,
    private utils: UtilsService
  ) {
  }

  private async howler() {
    if (this.utils.isBrowser) {
      if (this.importCache) {
        return this.importCache;
      }
      const { Howl, Howler } = await import('../../howler/howler.js');
      Howler.html5PoolSize = 1;
      this.importCache = { Howl, Howler };
      return this.importCache;
    } else {
      return { Howl: {}, Howler: {} };
    }
  }

  public play(track: Track, seek: number = 0, addToQueue: boolean = true) {
    this.clearPlaying();

    let audioNode;
    if (!this.unlockedAudio) {
      // We need to create the audio node before loading howler to unlock playback.
      // The unlock is supposed to be done by the howler library, but because
      // we are loading it lazily the browser will not unlock it properly.
      audioNode = new Audio();
    }

    if (!track) {
      return;
    }

    this.track = track;

    seek = this.getClampedSeek(seek);
    this.currentSeek = seek;

    this.howler().then(({ Howl, Howler }) => {
      if (!this.unlockedAudio && audioNode) {
        this.unlockedAudio = true;
        Howler._html5AudioPool = [audioNode];
      }
      Howler.stop();
      Howler.unload();
      this.howl = new Howl({
        src: [track.mp3],
        html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
        volume: this.volume,
        pool: 1,
        onplay: () => {
          if (this.howl) {
            this.duration = this.howl.duration();
            this.howl.seek(this.currentSeek * this.duration);
            this.startPeriodicUpdate();
          }
        },
        onload: () => {
          if (this.howl) {
            this.duration = this.howl.duration();
            this.howl.seek(seek * this.duration);
          }
        },
        onend: () => {
          if (track.isSoundEffect) {
            this.stop();
            this.track = null;
            this.onTrackChange.next(null);
          } else {
            this.next();
          }
        },
        onpause: () => {
          this.stopPeriodicUpdate();
        },
        onstop: () => {
          this.stopPeriodicUpdate();
        },
        onseek: () => {
          this.sendUpdate();
        },
        loaderror: (id, err) => {
          this.errorsService.track('MediaService error: loaderror. Track (' + track.id + '): ' + track.title, ['MediaService', 'LoadError']);
          this.hasError = true;
          this.sendUpdate();
          this.stop();
        },
        playerror: (id, err) => {
          this.errorsService.track('MediaService error: playerror. Track (' + track.id + '): ' + track.title, ['MediaService', 'PlayError']);
          this.hasError = true;
          this.stop();
        }

      });
      this.howl?.play();

      this.onTrackChange.next(track);
      this.onUpdate.next({
        duration: this.duration,
        currentTime: this.currentTime,
        seek: this.currentSeek,
        status: this.getStatus(),
        trackId: track.id
      });
      if (addToQueue) {
        this.queueService.add(track);
      }
    });
  }

  public resume() {
    if (this.hasError) {
      this.play(this.track);
    } else {
      this.howl?.play();
    }
  }

  public pause() {
    this.howl?.pause();
  }

  public stop() {
    this.clearPlaying();
  }

  public stopAndClear() {
    this.clearPlaying();
    this.track = null;
  }

  public seekTo(seek: number) {
    if (seek < 0) {
      seek = 0;
    }
    if (seek > 1) {
      seek = 1;
    }
    this.currentSeek = seek;
    this.currentTime = seek * this.duration;
    this.howl?.seek(seek * this.duration);
    this.onUpdate.next({
      duration: this.duration,
      currentTime: this.currentTime,
      seek,
      status: this.getStatus(),
      trackId: this.track?.id
    });
  }

  public previous() {
    if (this.queueService.hasPrev) {
      this.play(this.queueService.movePrev(), 0, false);
    }
  }

  public next() {
    this.stop();
    if (this.queueService.hasNext) {
      this.play(this.queueService.moveNext(), 0, false);
    } else {
      this.manualLoading = true;
      this.randomService.getTrack().subscribe(track => {
        this.manualLoading = false;
        this.play(track);
      });
    }
  }

  public setVolume(volume: number) {
    this.volume = volume;
    this.howl?.volume(volume);
  }

  public isTrackActive(trackId: number) {
    if (!trackId) {
      return false;
    }
    return this.track && trackId && this.track.id && this.track.id == trackId;
  }

  public isTrackPlaying(track: Track) {
    return this.isTrackActive(track?.id) && this.isPlaying();
  }

  public isPlaying() {
    return this.howl?.playing();
  }

  public isPaused() {
    return !this.isPlaying();
  }

  public isLoading() {
    return this.howl?.state() === 'loading' || this.manualLoading;
  }

  public isError() {
    return this.hasError;
  }

  private periodicIntervalId = null;

  private startPeriodicUpdate() {
    this.periodicIntervalId = setInterval(() => {
      if (!this.howl) {
        this.stopPeriodicUpdate();
      } else {
        this.sendUpdate();
      }
    }, 200);
  }

  private stopPeriodicUpdate() {
    if (this.periodicIntervalId) {
      this.periodicIntervalId = null;
      clearInterval(this.periodicIntervalId);
      this.sendUpdate();
    }
  }

  private sendUpdate() {
    this.duration = this.howl?.duration();
    this.currentSeek = this.duration && this.howl ? this.getClampedSeek(this.howl.seek() / this.duration) : 0;
    this.currentTime = this.currentSeek * this.duration;
    this.onUpdate.next({
      duration: this.duration,
      currentTime: this.currentTime,
      seek: this.currentSeek,
      status: this.getStatus(),
      trackId: this.track?.id,
    });
  }

  private async clearPlaying() {
    this.stopPeriodicUpdate();
    const { Howl, Howler } = await this.howler();
    Howler.stop();
    // Howler.unload();
    this.hasError = false;
    this.howl = null;
    this.duration = 0;
    this.currentTime = 0;
    this.currentSeek = 0;
    this.sendUpdate();
  }

  private getStatus() {
    if (this.hasError) {
      return PlayerStatus.Error;
    } else if (this.isLoading()) {
      return PlayerStatus.Loading;
    } else if (this.isPlaying()) {
      return PlayerStatus.Playing;
    } else {
      return PlayerStatus.Paused;
    }
  }

  private getClampedSeek(seek: number) {
    if (seek > 1) {
      return 1;
    } else if (seek < 0) {
      return 0;
    }
    return seek;
  }

}
